const getIdFromTrigger = (
  entries,
  id,
  finalTrigger,
  data,
  setArr,
  setHasOption,
) => {
  if (id === finalTrigger) {
    return;
  }
  const step = data.find((a) => a.id === id);
  if (!step) {
    return entries;
  }
  entries.push(step);

  if (step.options) {
    setHasOption(true);
  }
  if (step.conditionals) {
    console.log(step);
    const stepConditionals = step.conditionals.map((a) => {
      if (Object.prototype.hasOwnProperty.call(a, "options")) {
        setHasOption(true);
      }

      console.log({ step });
      const branchEntries = [];
      const vals = getIdFromTrigger(
        branchEntries,
        a.trigger,
        finalTrigger,
        data,
        setArr,
        setHasOption,
      );
      const objVals = { steps: vals, label: `${id}:${a.matches}` };

      setArr((prev) => [...prev, objVals]);
      console.log("objVals", objVals);
      console.log("objVals", step);
      return objVals;
    });
    entries = [...entries, ...stepConditionals];
  }

  if (!step.trigger) {
    return entries;
  }

  if (step.trigger === finalTrigger) {
    return entries;
  } else {
    return getIdFromTrigger(
      entries,
      step.trigger,
      finalTrigger,
      data,
      setArr,
      setHasOption,
    );
  }
};

export function onDragEnd(result, state) {
  console.log("called");
  if (!result.destination) return;

  // if(result.destination.index === 0) return

  // Create items copy
  const items = JSON.parse(JSON.stringify(state));

  // Indexes
  const sourceIndex = result.source.index;
  const destinationIndex = result.destination.index;

  // Move item
  const [item] = items.splice(sourceIndex, 1);
  items.splice(destinationIndex, 0, item);

  items.forEach((item, index, items) => {
    item.trigger = items[index + 1]?.id;
  });

  // Remove trigger from last element
  delete items[items.length - 1].trigger;

  return items;
}

export const orderData = (data, setArr, setHasOption) => {
  const firstEntry = data.find((a) => a.id.includes("-entry"));

  if (!firstEntry) return [];

  const newData = [];
  const finalTrigger = "temp";

  console.log(firstEntry, "firstentry");
  newData.push(firstEntry);
  const finalData = getIdFromTrigger(
    newData,
    firstEntry.trigger,
    finalTrigger,
    data,
    setArr,
    setHasOption,
  );

  return finalData;
};
