import React, { useContext, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import StyleContext from "../../styling/styleContext";

function VersionConfirmationModal({ open, hideModal, version, rollBack }) {
  const style = useContext(StyleContext);
  const [confirmationField, setConfirmationField] = useState("");
  return (
    <Modal show={open} onHide={hideModal}>
      <Modal.Header closeButton>
        <h3 style={{ color: style.colors.blue }}>Are You Sure?</h3>
      </Modal.Header>

      <Modal.Body style={{ ...style.styleSizing, color: style.colors.blue }}>
        {`Are you sure you want to rollback to ${version}`}
        <Form>
          <Form.Group>
            <Form.Control
              onChange={(e) => {
                setConfirmationField(e.target.value);
              }}
              value={confirmationField}
              placeholder="type 'confirm'"
            />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          style={{ ...style.submitButton(style.colors.green), height: 50 }}
          onClick={() => {
            setConfirmationField("");
            hideModal();
          }}
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          style={{ ...style.deleteButton, height: 50 }}
          onClick={() => {
            setConfirmationField("");
            rollBack();
          }}
          variant="primary"
          disabled={confirmationField.toLowerCase() !== "confirm"}
        >
          Confirm Rollback
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VersionConfirmationModal;
