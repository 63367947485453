import React, { useContext } from "react";
import { FormControl } from "react-bootstrap";
import StyleContext from "../../../../styling/styleContext";

const UserInput = ({ botStep, setBotStep }) => {
  const style = useContext(StyleContext);
  const handleChange = (input) => {
    setBotStep({
      ...botStep,
      component: {
        type: "userinput",
        placeholder: input,
      },
    });
  };

  return (
    <>
      <h6 style={{ ...style.styleSizing, color: style.colors.blue }}>
        User Input
      </h6>
      <FormControl
        style={{
          fontStyle: style.styleSizing.fontStyle,
          color: style.colors.blue,
        }}
        className="modal-input"
        onChange={(e) => handleChange(e.target.value)}
        type="text"
        placeholder="placeholder"
      />
    </>
  );
};

export default UserInput;
