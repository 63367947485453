import React from "react";

export const MeasurItIframeScreen = () => {
  return (
    <>
      <div className="page-title">
        <div className="title">Measur.It</div>
        <div className="sub-title"></div>
      </div>

      <div className="row">
        <div className="col-md-12">
          {/* <div className="card bg-white card-block"> */}
          <iframe
            title={"measurit"}
            style={{ width: "100%", height: 750, border: 0 }}
            key={"meauritframe"}
            src="https://draw.measur.it/demo/index.html"
          ></iframe>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};
