import React, { Fragment, useState, useContext, useCallback } from "react";
import { Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import styleContext from "../../styling/styleContext";
import { API } from "aws-amplify";
import DiscountForm from "./discounts/discount-form";
import { useEffect } from "react";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../../components/CompanySelector.jsx";

const EditProgram = ({ program, setActive, onSaveComplete }) => {
  const [validated, setValidated] = useState(false);

  const style = useContext(styleContext);
  const [discountTables, setDiscountTables] = useState({});
  const [programTypes, setProgramTypes] = useState([]);
  const notify = (val) => toast(val);
  const activeCompanySnapshot = useSnapshot(activeCompanyState);

  const formSubmit = useCallback(
    async (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      const formData = new FormData(event.target);
      const formDataObj = Object.fromEntries(formData.entries());

      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        return;
      }

      try {
        await API.post("dashboardapi", "/v1/programs", {
          headers: {
            "Company-Id": activeCompanySnapshot?.companyId,
          },
          body: {
            id: program.id === "newId" ? null : program.id,
            ...formDataObj,
            alwaysOnTop: program?.alwaysOnTop !== undefined ? program.alwaysOnTop : false,
            useRealGreenPricingEngine:
              formDataObj.useRealGreenPricingEngine || false,
            realGreenApplicationDiscountCode:
              formDataObj.useRealGreenPricingEngine
                ? JSON.stringify(discountTables)
                : null,
            realGreenPrepayDiscountCode: formDataObj.useRealGreenPricingEngine
              ? formDataObj.realGreenPrepayDiscountCode
              : null,
          },
        });
        onSaveComplete();
      } catch (err) {
        console.log("err: ", err);
        notify("Failed to Modify Program");
      }
    },
    [setValidated, program, discountTables, activeCompanySnapshot?.companyId],
  );

  useEffect(() => {
    const getProgramTypes = async () => {
      const data = await API.get("dashboardapi", "/v1/service-types", {
        headers: {
          "Company-Id": activeCompanySnapshot?.companyId,
        },
      });
      const mappedData = data?.message?.map((item) => {
        return { label: item.name, value: item.name };
      });
      mappedData.unshift({ label: "", value: "" });
      setProgramTypes(mappedData);
    };
    getProgramTypes();
    const discounts =
      !program.realGreenDiscountTable &&
      !program.realGreenApplicationDiscountCode
        ? []
        : JSON.parse(program.realGreenApplicationDiscountCode);
    setDiscountTables(discounts);
  }, [program]);

  const modifyHeaders = {
    ...style.styleSizing,
    color: style.colors.blue,
    paddingLeft: 5,
  };

  const modifyInputs = {
    ...style.styleSizing,
    color: style.colors.green,
    fontSize: 14,
  };

  return (
    <Form
      noValidate
      validated={validated}
      key="edit-program"
      style={{ maxWidth: "1140px" }}
      onSubmit={formSubmit}
    >
      <br />
      <hr />
      <Fragment key={`edit-program-1`}>
        <h2
          style={{
            ...style.styleSizing,
            fontSize: 24,
            fontWeight: 500,
            marginBottom: 15,
          }}
          id="real-green-header"
        >
          Modify Program
        </h2>

        <div
          style={{
            background: "whitesmoke",
            marginLeft: " 0px",
            borderRadius: "25px",
            padding: "50px",
          }}
          className="container"
        >
          <div key={"programs_form_1"}>
            <Form.Group check inline>
              <Form.Label
                style={{ ...style.styleSizing, color: style.colors.blue }}
                check
              >
                <Form.Check.Input
                  style={{ marginLeft: "4px", marginTop: "7px" }}
                  name="active"
                  type="checkbox"
                  value={true}
                  defaultChecked={program.active}
                />
                <div style={{ paddingLeft: "25px" }}>Program Active</div>
              </Form.Label>
            </Form.Group>

            <Form.Group controlId="formProgramName">
              <Form.Label
                style={{ ...modifyHeaders }}
                className="context edit-label"
                id="program-name"
              >
                Program Name
              </Form.Label>
              <Form.Control
                required
                style={{
                  ...modifyInputs,
                  boxShadow: "0 0 10px rgba(0,0,0,0.15)",
                }}
                type="text"
                name="programName"
                defaultValue={program.programName}
              />
              <Form.Control.Feedback type="invalid">
                Program Name is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formProgramDescription">
              <Form.Label
                style={{ ...modifyHeaders }}
                className="context edit-label"
                id="program-description"
              >
                Program Description
              </Form.Label>
              <Form.Control
                required
                style={{
                  ...modifyInputs,
                  boxShadow: "0 0 10px rgba(0,0,0,0.15)",
                }}
                type="text"
                name="programDescription"
                defaultValue={program.programDescription}
                as="textarea"
                rows={5}
              />
              <Form.Control.Feedback type="invalid">
                Program Description is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formStartMonth">
              <Form.Label
                style={{ ...modifyHeaders }}
                className="context edit-label"
                id="start-month"
              >
                Start Month
              </Form.Label>
              <Form.Label>
                <Form.Control
                  as="select"
                  style={{
                    ...modifyInputs,
                    margin: "10px",
                    padding: "5px",
                    backgroundPosition: "calc(100% - 20px) center",
                    minWidth: "130px",
                  }}
                  defaultValue={program?.programStartMonth || "1"}
                  name="programStartMonth"
                >
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </Form.Control>
              </Form.Label>
              {/* <Form.Control style={{ boxShadow: '0 0 10px rgba(0,0,0,0.15)'}} onChange={handleChange} name='programEndMonth' type="number" value={values.programEndMonth || a.programEndMonth} /> */}
            </Form.Group>
            <Form.Group controlId="formEndMonth">
              <Form.Label
                style={{ ...modifyHeaders }}
                className="context edit-label"
                id="end-month"
              >
                End Month
              </Form.Label>
              <Form.Label>
                <Form.Control
                  as="select"
                  style={{
                    ...modifyInputs,
                    margin: "10px",
                    padding: "5px",
                    backgroundPosition: "calc(100% - 20px) center",
                    minWidth: "130px",
                  }}
                  defaultValue={program.programEndMonth || "12"}
                  name="programEndMonth"
                >
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April </option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </Form.Control>
              </Form.Label>
            </Form.Group>

            <Form.Group controlId="programType">
              <Form.Label
                style={{ ...modifyHeaders }}
                className="context edit-label"
                id="program-type"
              >
                Program Type
              </Form.Label>

              {programTypes?.length ? (
                <Form.Control
                  required
                  as="select"
                  style={{
                    ...modifyInputs,
                    margin: "10px",
                    padding: "5px",
                    backgroundPosition: "calc(100% - 20px) center",
                  }}
                  defaultValue={program.programType}
                  name="programType"
                >
                  {programTypes.map((types, typeI) => (
                    <option key={`${types.label}-${typeI}`} value={types.label}>
                      {types.label}
                    </option>
                  ))}
                </Form.Control>
              ) : null}
              <Form.Control.Feedback
                type="invalid"
                style={{ paddingLeft: "8px", marginTop: "-4px" }}
              >
                Program Type is required.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="alternativeApplicationString">
              <Form.Label
                style={{ ...modifyHeaders }}
                className="context edit-label"
                id="alternative-application-label"
              >
                Alternative Application Label
              </Form.Label>
              <Form.Control
                required
                style={{
                  ...modifyInputs,
                  boxShadow: "0 0 10px rgba(0,0,0,0.15)",
                }}
                type="text"
                name="alternativeApplicationString"
                defaultValue={
                  program?.alternativeApplicationString || "application"
                }
              />
              <Form.Control.Feedback type="invalid">
                Alternative Application Label is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="alternativePaymentVerbiage">
              <Form.Label
                style={{ ...modifyHeaders }}
                className="context edit-label"
                id="alternative-application-label"
              >
                Alternative Payment Verbiage
              </Form.Label>
              <Form.Control
                required
                style={{
                  ...modifyInputs,
                  boxShadow: "0 0 10px rgba(0,0,0,0.15)",
                }}
                type="text"
                name="alternativePaymentVerbiage"
                defaultValue={
                  program?.alternativePaymentVerbiage || "installment"
                }
              />
              <Form.Control.Feedback type="invalid">
                Alternative Payment Verbiage is required.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group check inline controlId="isUpsell">
              <Form.Label
                style={{ ...style.styleSizing, color: style.colors.blue }}
                check
              >
                <Form.Check.Input
                  style={{ marginLeft: "4px", marginTop: "7px" }}
                  name="isUpsell"
                  type="checkbox"
                  defaultChecked={program?.isUpsell}
                />
                <div style={{ paddingLeft: "25px" }}>Is Upsell</div>
              </Form.Label>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-isUpsell">
                    <p style={{ textAlign: "left" }}>
                      All offerings are set to default as programs. Please check
                      the Upsell option to assign a lower position to this
                      offering, which will be displayed under the Terms of
                      Service.
                    </p>
                  </Tooltip>
                }
              >
                <span style={{ cursor: "pointer", marginLeft: "5px" }}>
                  <i className="icon-info mr5" />
                </span>
              </OverlayTrigger>
            </Form.Group>
            {/* <Form.Group check inline controlId="alwaysOnTop">
              <Form.Label
                style={{ ...style.styleSizing, color: style.colors.blue }}
                check
              >
                <Form.Check.Input
                  style={{ marginLeft: "4px", marginTop: "7px" }}
                  name="alwaysOnTop"
                  type="checkbox"
                  defaultChecked={program?.alwaysOnTop}
                />
                <div style={{ paddingLeft: "25px" }}>Always On Top</div>
              </Form.Label>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="tooltip-alwaysOnTop">
                    <p style={{ textAlign: "left" }}>
                      Offerings are presented in a random order on the checkout
                      page. Please select this option to prioritize this
                      offering at the top of the list.
                    </p>
                    <p style={{ textAlign: "left" }}>
                      <i>**Only select this option for one program</i>
                    </p>
                  </Tooltip>
                }
              >
                <span style={{ cursor: "pointer", marginLeft: "5px" }}>
                  <i className="icon-info mr5" />
                </span>
              </OverlayTrigger>
            </Form.Group> */}
            <Form.Group controlId="seasonalText">
              <Form.Label
                style={{ ...modifyHeaders }}
                className="context edit-label"
                id="seasonalText"
              >
                Seasonal
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-seasonalText">
                      <p style={{ textAlign: "left" }}>
                        Seasonal text works with the Alternative Application
                        Label to make a complete statement. Select the statement
                        ending you would like to use with the Alternative
                        Application Label. E.g.,
                        <ul>
                          <li>6 Applications (Empty)</li>
                          <li>6 Applications Remaining (Remaining)</li>
                          <li>
                            6 Applications Remaining This Year (Remaining This
                            Year)
                          </li>
                          <li>
                            6 Applications Remaining This Season (Remaining This
                            Season)
                          </li>
                        </ul>
                      </p>
                    </Tooltip>
                  }
                >
                  <span style={{ cursor: "pointer", marginLeft: "5px" }}>
                    <i className="icon-info mr5" />
                  </span>
                </OverlayTrigger>
              </Form.Label>

              <div style={{ margin: "10px", padding: "5px" }}>
                <Form.Check
                  type="radio"
                  label="<Empty>"
                  name="seasonalText"
                  value="empty"
                  defaultChecked={program?.seasonalText === "empty"}
                  style={{ ...modifyInputs, marginBottom: "5px" }}
                />
                <Form.Check
                  type="radio"
                  label="Remaining"
                  name="seasonalText"
                  value="remaining"
                  defaultChecked={
                    program?.seasonalText !== undefined
                      ? program?.seasonalText === "remaining"
                      : true
                  }
                  style={{ ...modifyInputs, marginBottom: "5px" }}
                />
                <Form.Check
                  type="radio"
                  label="Remaining This Year"
                  name="seasonalText"
                  value="year"
                  defaultChecked={program?.seasonalText === "year"}
                  style={{ ...modifyInputs, marginBottom: "5px" }}
                />
                <Form.Check
                  type="radio"
                  label="Remaining This Season"
                  name="seasonalText"
                  value="season"
                  defaultChecked={program?.seasonalText === "season"}
                  style={{ ...modifyInputs }}
                />
              </div>
            </Form.Group>

            <Form.Group>
              <div>
                <DiscountForm
                  program={program}
                  rounds={discountTables}
                  setRounds={(newRounds) => {
                    setDiscountTables(newRounds);
                  }}
                />
              </div>
            </Form.Group>

            <Button
              type="submit"
              style={{
                background: "#009879",
                ...style.submitButton(style.colors.green),
              }}
              variant="primary"
            >
              Submit
            </Button>
            <Button
              style={{
                margin: "10px",
                ...style.submitButton("#DD6777"),
                borderColor: "#DD6777",
              }}
              onClick={() => setActive(false)}
              variant="danger"
              type="submit"
            >
              Close
            </Button>
          </div>
          <div />
        </div>
      </Fragment>
      <br />
      <hr />
      <ToastContainer />
    </Form>
  );
};

export default EditProgram;
