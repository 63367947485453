import React, { useEffect, useState, useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { StyleContext } from "../../../styling/styleContext";
import {
  Container,
  Row,
  Col,
  Button,
  Accordion,
  Card,
  Spinner,
  AccordionContext,
  useAccordionToggle,
} from "react-bootstrap";

import { MessageStepDisplay } from "./MessageStepDisplay.jsx";

function CustomToggle({ children, eventKey, setToggle, toggle }) {
  const style = useContext(StyleContext);
  // const [toggle, setToggle] = useState(false);
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => setToggle(!toggle),
    // console.log("totally custom!")
  );

  return (
    <Button
      type="button"
      style={{
        ...style.styleSizing,
        backgroundColor: style.colors.green,
        fontSize: 16,
      }}
      onClick={decoratedOnClick}
    >
      {children}
    </Button>
  );
}

function AccordionComp({ accordions }) {
  const [toggled, setToggled] = useState(false);
  const style = useContext(StyleContext);
  const [activeKey, setActiveKey] = useState(
    sessionStorage.getItem("selected"),
  );
  console.log({ activeKey });

  const onSelect = (index) => {
    const selected = String(index);
    sessionStorage.setItem("selected", selected);
    // setActiveKey(selected);
    console.log({ selected });
  };

  return (
    <Accordion defaultActiveKey={activeKey} onSelect={onSelect}>
      <Card
        style={{
          textAlign: "left",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Card.Header style={{ ...style.styleSizing, fontWeight: 400 }}>
          <CustomToggle
            onClick={() => console.log("clicked")}
            style={{ background: style.colors.green }}
            eventKey="0"
            toggle={toggled}
            setToggle={setToggled}
          >
            {toggled ? "Close Additional Options" : "Open Additional Options"}
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse appear={true} eventKey="0">
          <Row style={{ width: "60%" }}>
            {accordions.map(({ value }, index) => {
              return (
                <Col key={`Options_Col_${index}`} xs="12" lg="6" md="6">
                  <Card style={{ width: "auto", height: "150px" }}>
                    <Card.Header
                      style={{
                        backgroundColor: style.colors.green,
                        color: "white",
                        fontFamily: style.styleSizing.fontFamily,
                      }}
                    >{`Option ${index + 1}`}</Card.Header>
                    <Card.Body>{value}</Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

const MessageDraggable = ({
  step: message,
  provided,
  index,
  updateBotQuestions,
  type,
  botId,
  openModal,
}) => {
  const style = useContext(StyleContext);
  const isDragDisabled = index === 0;

  return !message ? null : (
    <div data-testid="has-options">
      <Draggable
        key={`draggable_key_${index}`}
        draggableId={`${index + 0}`}
        index={index}
        isDragDisabled={isDragDisabled}
      >
        {(provided) => (
          <MessageStepDisplay
            provided={provided}
            openModal={openModal}
            message={message}
          />
        )}
      </Draggable>
    </div>
  );
};

export default MessageDraggable;
