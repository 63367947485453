import React, { useState } from "react";
import { Button } from "reactstrap";

import { Form, Spinner } from "react-bootstrap";

import useCreateUser from "../../hooks/users/useCreateUser";
import { ToastContainer } from "react-toastify";

const CreateUser = ({ users, setUsers, companyId }) => {
  const [newUser, setNewUser] = useState({ name: "", email: "" });
  const [isAdmin, setIsAdmin] = useState(false);
  const { createUser, loading } = useCreateUser();

  const createUserCb = async () => {
    const roles = [];
    if (isAdmin) {
      roles.push("Admin");
    } else {
      roles.push("User");
    }

    const user = await createUser({
      email: newUser.email,
      name: newUser.name,
      roles,
      companyId,
    });

    if (user?.message) {
      const u = {
        email: newUser.email.toLowerCase(),
        name: newUser.name,
        roles: JSON.stringify(roles),
      };
      const allu = [...users];
      allu.push(u);
      setUsers(allu);
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card bg-white card-block">
          <h5>Users</h5>
          <ul>
            {users
              ? users.map((i) => {
                  const key = `branch_id_${Math.floor(Math.random() * 10000)}`;
                  const roles = JSON.parse(i.roles);
                  const rolesj = roles.join(", ");

                  return (
                    <li key={key}>
                      {i.name} - {i.email} - {rolesj}
                    </li>
                  );
                })
              : null}
          </ul>

          <>
            <input
              style={{ width: 200 }}
              value={newUser.email}
              onChange={(e) => {
                const payload = { ...newUser };
                payload.email = e.target.value;
                setNewUser(payload);
              }}
              placeholder={"email address"}
            />
            <br />
            <input
              style={{ width: 200 }}
              value={newUser.name}
              onChange={(e) => {
                const payload = { ...newUser };
                payload.name = e.target.value;
                setNewUser(payload);
              }}
              placeholder={"name"}
            />
            <Form.Group
              style={{ marginTop: 10 }}
              className="mb-3"
              controlId="formBasicCheckbox"
            >
              <Form.Check
                onChange={(e) => {
                  setIsAdmin(e.target.checked);
                }}
                type="checkbox"
                label="Admin"
              />
            </Form.Group>
            <br />
            <Button
              onClick={createUserCb}
              style={{ width: 150 }}
              disabled={loading}
            >
              {loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Create User"
              )}
            </Button>

            <ToastContainer />
          </>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
