import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  Fragment,
} from "react";
import { FormGroup, Label } from "reactstrap";
import DiscountTable from "./discount-table";
import { API } from "aws-amplify";
import { Spinner } from "react-bootstrap";
import StyleContext from "../../../styling/styleContext";
import { Form } from "react-bootstrap";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../../../components/CompanySelector.jsx";

const DiscountForm = ({ program, rounds, setRounds }) => {
  const style = useContext(StyleContext);
  const [isRTP, setRTP] = useState(program.useRealGreenPricingEngine);
  const [discountCodes, setDiscountCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rgPrograms, setRGPrograms] = useState([]);
  const activeCompanySnapshot = useSnapshot(activeCompanyState);

  useEffect(() => {
    async function fetchDiscountCodes() {
      try {
        setLoading(true);
        const data = await API.get(
          "dashboardapi",
          "/v1/dashboard/discounts",
          {},
        );
        const rgPrograms = await API.get(
          "dashboardapi",
          "/v1/dashboard/programs",
          {
            headers: {
              "Company-Id": activeCompanySnapshot?.companyId,
            },
          },
        );
        const loadedPrograms = rgPrograms ? rgPrograms.programs : [];
        const lodadedDiscountCounts = data ? data.discounts : [];
        const sortedPrograms = loadedPrograms.sort((a, b) => {
          if (a.prgm_desc === b.prgm_desc) {
            return 0;
          }
          return a.prgm_desc < b.prgm_desc ? -1 : 1;
        });
        setRGPrograms(sortedPrograms);

        const sortedDiscountCodes = lodadedDiscountCounts.sort((a, b) => {
          if (a.discountDescription === b.discountDescription) {
            return 0;
          }
          return a.discountDescription < b.discountDescription ? -1 : 1;
        });
        setDiscountCodes(sortedDiscountCodes);
      } catch (error) {
        console.log("Error fetching discount codes", error);
      }
      setLoading(false);
    }
    fetchDiscountCodes();
  }, [activeCompanySnapshot?.companyId]);

  const changeRTP = useCallback(() => {
    setRTP(!isRTP);
  }, [isRTP]);

  const dropDownLabelStyling = {
    ...style.styleSizing,
    color: style.colors.green,
    fontSize: 16,
  };

  const inputStyling = {
    ...style.styleSizing,
    ...style.dropDown,
    color: style.colors.blue,
    fontSize: 12,
    backgroundPosition: "calc(100% - 20px) center",
  };

  return (
    <Fragment>
      <FormGroup check inline>
        <Label style={{ ...style.styleSizing, color: style.colors.blue }} check>
          <Form.Check.Input
            style={{ marginLeft: "4px", marginTop: "7px" }}
            name="useRealGreenPricingEngine"
            type="checkbox"
            onChange={() => changeRTP(!isRTP)}
            value={true}
            defaultChecked={program.useRealGreenPricingEngine}
          />
          Use Real Green&apos;s Pricing Engine
        </Label>
      </FormGroup>
      {loading ? (
        <div id="crm-spinner">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Fragment>
          {isRTP ? (
            <Fragment>
              <FormGroup style={style.programsDiv}>
                <Label style={dropDownLabelStyling} for="exampleSelect">
                  Real Green Program
                </Label>
                {/* biome-ignore lint/complexity/useOptionalChain: <explanation> */}
                {rgPrograms && rgPrograms.length && (
                  <Form.Control
                    required={isRTP}
                    as="select"
                    style={inputStyling}
                    type="select"
                    name="crmProgamCode"
                    className="exampleSelect"
                    defaultValue={program.crmProgamCode}
                  >
                    <option value="" />
                    {rgPrograms
                      .filter((i) => i.available)
                      .map((program, i) => {
                        return (
                          <option
                            id={program.progdefid}
                            value={`${program.progdefid}`}
                            key={`program_form_key ${i}`}
                          >
                            {`${program.prgm_desc} (${program.prgm_code})`}
                          </option>
                        );
                      })}
                  </Form.Control>
                )}
                <Form.Control.Feedback type="invalid">
                  Real Green Program is required.
                </Form.Control.Feedback>
              </FormGroup>

              <FormGroup style={style.programsDiv}>
                <Label style={dropDownLabelStyling} for="exampleSelect">
                  Pre-pay Discount Code
                </Label>
                {discountCodes?.length && (
                  <Form.Control
                    as="select"
                    required={isRTP}
                    style={inputStyling}
                    name="realGreenPrepayDiscountCode"
                    className="exampleSelect"
                    defaultValue={
                      program.realGreenPrepayDiscountCode
                        ? program.realGreenPrepayDiscountCode
                        : ""
                    }
                  >
                    <option value="" />
                    {discountCodes
                      .filter((i) => i.available)
                      .filter((i) => i.discountDescription.trim())
                      .filter((i) => i.dollarDiscount === 1)
                      .map((discount, i) => {
                        const discountAmount = discount.discountAmount;
                        const discountType =
                          discount.dollarDiscount === 1
                            ? `${discountAmount}% Off`
                            : `$${discountAmount}${
                                discount.dollarDiscount === 3
                                  ? " Fixed Price"
                                  : " Off"
                              }`;
                        return (
                          <option
                            id={discount.id}
                            value={`${discount.id}`}
                            key={`discount_form_key ${i}`}
                          >
                            {discount.discountDescription} ({discountType})
                          </option>
                        );
                      })}
                  </Form.Control>
                )}
                <Form.Control.Feedback type="invalid">
                  Pre-pay Discount Code is required.
                </Form.Control.Feedback>
              </FormGroup>
            </Fragment>
          ) : null}
        </Fragment>
      )}
      {isRTP ? (
        <Fragment>
          <br />
          <hr />

          <DiscountTable
            rounds={rounds}
            discountCodes={discountCodes}
            setRounds={setRounds}
          />
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default DiscountForm;
