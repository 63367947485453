import React, { useEffect, useState } from "react";
import { KpiRow } from "../widgets/metrics/kpi-row";
import { StackedLineGraph } from "../widgets/graphs/stacked-line";
import { Metric } from "../models/metric-interfaces";

import * as _ from "lodash";

import { API } from "aws-amplify";
import { DonutPieChart } from "../widgets/graphs/donut";
import LoadingOverlay from "react-loading-overlay";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../components/CompanySelector.jsx";
import getCurrencySymbol from "../utils/get-currency-symbol.js";

export const DashboardScreen = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [metricsLoaded, setMetricsLoaded] = useState(false);

  const [quoteSeries, setQuoteSeries] = useState([]);
  const [salesSeries, setSalesSeries] = useState([]);
  const [breakdown, setBreakdown] = useState([]);
  const [pieSeries, setPieSeries] = useState([]);

  const [metrics, setMetrics] = useState([
    new Metric(0, "Chats", 2, "#ffffff"),
    new Metric(0, "Leads", 3, "#FCDC69"),
    new Metric(0, "Sales", 3, "#22D4AE"),
    new Metric(0, "Revenue", 4, "#35B459"),
  ]);

  const [currency, setCurrency] = useState("");
  const activeCompanySnapshot = useSnapshot(activeCompanyState);

  useEffect(() => {
    async function fetchCompany() {
      setLoaded(false);
      const response = await API.get("dashboardapi", "/v1/companys", {
        headers: {
          "Company-Id": activeCompanySnapshot?.companyId,
        },
      });
      setCurrency(response.message.data.getCompanyVersioned.currency);
      setLoaded(true);
    }
    fetchCompany();
  }, [activeCompanySnapshot?.companyId]);
  const companyCurrency = getCurrencySymbol(currency);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const run = async () => {
      setMetricsLoaded(false);
      const result = await API.get("dashboardapi", "/v1/dashboard/metrics", {
        headers: {
          "Company-Id": activeCompanySnapshot?.companyId,
        },
      });
      if (result) {
        let b = [];
        for (const t in result.serviceTypeBreakdown) {
          b.push({
            serviceType: t,
            quoted: result.serviceTypeBreakdown[t].quoted,
            sold: result.serviceTypeBreakdown[t].sold,
          });
        }

        b = _.sortBy(b, ["quoted"]);
        setBreakdown(b);

        const pie = b.map((i) => {
          return { label: i.serviceType, data: i.sold };
        });

        setPieSeries(pie);

        const new_metrics = [
          new Metric(
            result.chats.toLocaleString(),
            "Chats",
            2,
            "#ffffff",
            "#000000",
            "/quotes",
          ),
          new Metric(
            result.quotes.toLocaleString(),
            "Leads",
            3,
            "#FCDC69",
            null,
            "/quotes/leads",
          ),
          new Metric(
            result.sales.toLocaleString(),
            "Sales",
            3,
            "#22D4AE",
            null,
            "/quotes/sales",
          ),
          new Metric(
            companyCurrency +
              Number.parseFloat(result.revenue.toFixed(2)).toLocaleString(),
            "Revenue",
            4,
            "#35B459",
          ),
        ];
        setMetrics(new_metrics);

        let ss = [];

        if (result.timeSeries?.sales) {
          const sorteds = _.sortBy(result.timeSeries.sales, [(o) => o[0]]);
          for (let i = 1; i < sorteds.length; i++) {
            if (i === 1) {
              sorteds[0][0] = roundDate(sorteds[0][0]);
            }
            sorteds[i][0] = roundDate(sorteds[i][0]);
          }

          const combineds = sorteds.map((m) => {
            const items = sorteds.filter((t) => t[0] === m[0]);
            const sum = _.reduce(items, (ss, a) => ss + a[1], 0);
            return [m[0], sum];
          });

          ss = _.uniqWith(combineds, _.isEqual);

          setSalesSeries(ss);
        }

        if (result.timeSeries?.quotes) {
          const sortedq = _.sortBy(result.timeSeries.quotes, [(o) => o[0]]);

          for (let i = 1; i < sortedq.length; i++) {
            if (i === 1) {
              sortedq[0][0] = roundDate(sortedq[0][0]);
            }
            sortedq[i][0] = roundDate(sortedq[i][0]);
          }

          const combinedq = sortedq.map((m) => {
            const items = sortedq.filter((t) => t[0] === m[0]);
            const sum = _.reduce(items, (ss, a) => ss + a[1], 0);
            return [m[0], sum];
          });

          const cc = _.uniqWith(combinedq, _.isEqual);

          setQuoteSeries(cc);
        }

        setTimeout(() => {
          setMetricsLoaded(true);
        }, 300);
      }
    };
    setMetricsLoaded(false);
    run();
  }, [activeCompanySnapshot?.companyId]);

  const roundDate = (timeStampValue) => {
    let timeStamp = timeStampValue;
    timeStamp -= timeStamp % (24 * 60 * 60 * 1000); //subtract amount of time since midnight
    timeStamp += new Date().getTimezoneOffset() * 60 * 1000; //add on the timezone offset
    return new Date(timeStamp).valueOf();
  };

  return !loaded || !metricsLoaded ? (
    <div
      style={{
        height: "100vh",
        backgroundColor: "black",
        paddingTop: "25vh",
        fontSize: 20,
        opacity: 0.8,
      }}
    >
      <LoadingOverlay
        active={!loaded || !metricsLoaded}
        spinner={
          <>
            <img
              src="/LB_loader.gif"
              style={{ width: 180, marginBottom: 40 }}
              alt=""
            />
            <br />
          </>
        }
        text="Loading Metrics... This could take a while"
      />
    </div>
  ) : (
    <>
      <KpiRow metrics={metrics} />

      <div className="row" style={{ minHeight: 300 }}>
        <div className="col-md-4" style={{ backgroundColor: "#ffffff" }}>
          {pieSeries.length ? (
            <DonutPieChart title={"Sales Breakdown"} series={pieSeries} />
          ) : null}
        </div>
        <div className="col-md-4" style={{ backgroundColor: "#4E7CFF" }}>
          <div className="card-block row-equal align-middle">
            <table style={{ width: "100%", fontSize: 16, color: "white" }}>
              <thead>
                <th>Service Type</th>
                <th>Quoted</th>
                <th>Sold</th>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="3">
                    <hr />
                  </td>
                </tr>
                {loaded &&
                  breakdown.map((i) => {
                    const bdkey = `bdkey_${Math.floor(Math.random() * 999999)}`;
                    return (
                      <tr key={bdkey}>
                        <td>
                          <strong>{i.serviceType}</strong>
                        </td>
                        <td>
                          <span style={{ color: "#FCDC69" }}>
                            {companyCurrency}
                            {typeof i.quoted !== "undefined" &&
                            i.quoted !== null
                              ? Number.parseFloat(
                                  i.quoted.toFixed(2),
                                ).toLocaleString()
                              : ""}
                          </span>
                        </td>
                        <td>
                          <span style={{ color: "#22D4AE" }}>
                            {companyCurrency}
                            {typeof i.sold !== "undefined" && i.sold !== null
                              ? Number.parseFloat(
                                  i.sold.toFixed(2),
                                ).toLocaleString()
                              : ""}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-4" style={{ backgroundColor: "#ffffff" }}>
          {loaded ? (
            <StackedLineGraph
              title={"Activity"}
              series={[
                {
                  label: "Quotes",
                  data: quoteSeries,
                  color: "#0000ff",
                  clickable: true,
                },
                {
                  label: "Sales",
                  data: salesSeries,
                  color: "#00ff00",
                  clickable: true,
                },
              ]}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
