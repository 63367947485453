import React, { useCallback, useMemo } from "react";
import { Input, Label } from "reactstrap";
import "./ButtonLoader.css";
import { proxy } from "valtio";

export const activeCompanyState = proxy({ companyId: null });

export function CompanySelector(props) {
  const { branches } = props;
  const setActiveCompany = useCallback(
    (companyId) => {
      activeCompanyState.companyId = companyId;
    },
    [activeCompanyState],
  );

  const showSelector = useMemo(() => {
    const windowLocation = window.location.pathname;
    return (
      windowLocation?.indexOf("measurit") == -1 &&
      windowLocation?.indexOf("/details") == -1 &&
      windowLocation?.indexOf("/settings") == -1
    );
  }, [window.location.pathname]);

  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        left: "220px",
        zIndex: 10000,
      }}
    >
      {showSelector && !!branches && branches.length > 1 && (
        <>
          <Label
            style={{
              paddingRight: "8px",
              color: "#FFF",
              fontWeight: "bold",
              marginLeft: "8px",
            }}
          >
            Active Company:{" "}
          </Label>
          <Input
            onChange={(e) => setActiveCompany(e.target.value)}
            type="select"
            name="select"
            value={activeCompanyState.companyId}
          >
            {window.location.pathname.indexOf("setup") == -1 && (
              <option value="">All Companies</option>
            )}
            {branches.map((b) => (
              <option value={b.builderCompanyId} key={b.builderCompanyId}>
                {b.companyName}
              </option>
            ))}
          </Input>
        </>
      )}
    </div>
  );
}
