import React from "react";
import PropTypes from "prop-types";

import { Pagination } from "react-bootstrap";

const TablePagination = (props) => {
  const itemArr = [];
  for (let i = 0; i < props.pageCount; i++) {
    itemArr.push(
      <Pagination.Item
        active={props.currentPage === i + 1}
        key={i + 1}
        onClick={
          props.currentPage !== i + 1 ? () => props.changePage(i + 1) : () => {}
        }
      >
        {i + 1}
      </Pagination.Item>,
    );
  }
  return (
    <div className="pagination">
      <Pagination.First
        disabled={props.currentPage === 1}
        onClick={() => props.changePage(1)}
      />
      <Pagination.Prev
        disabled={props.currentPage === 1}
        onClick={() => props.changePage(props.currentPage - 1)}
      />
      <Pagination aria-label="Page navigation example">{itemArr}</Pagination>
      <Pagination.Next
        disabled={props.currentPage === itemArr.length + 1}
        onClick={() => props.changePage(props.currentPage + 1)}
      />
      <Pagination.Last
        disabled={props.currentPage === itemArr.length}
        onClick={() => props.changePage(itemArr.length)}
      />
    </div>
  );
};

TablePagination.propTypes = {
  changePage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  total: PropTypes.number,
};

export { TablePagination };
