import React, { useEffect, useState, Fragment } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { API } from "aws-amplify";
import { useRouteMatch } from "react-router-dom";
import PublishButton from "../selfserve/generate-publish/PublishButton.js";
import {
  CompanySelector,
  activeCompanySnapshot,
  activeCompanyState,
} from "../components/CompanySelector.jsx";
import { useSnapshot } from "valtio";
export const TopHeader = (props) => {
  const { branches } = props;

  useRouteMatch();
  const tabCheck = window.location.pathname.split("/")[1];
  const [bots, setBots] = useState([]);
  const [companyId, setCompanyId] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedBot, setSelectedBot] = useState({});
  const activeCompanySnapshot = useSnapshot(activeCompanyState);

  useEffect(() => {
    const fn = async () => {
      const company = await API.get("dashboardapi", "/v1/company", {
        headers: {
          "Company-Id": activeCompanySnapshot?.companyId,
        },
      });
      setBots(company.bots ? company.bots.items : []);
      setSelectedBot(company.bots ? company.bots.items[0] : {});
      setCompanyId(company.id);
    };
    if (tabCheck === "setup") {
      fn();
    }
  }, [tabCheck, activeCompanySnapshot?.companyId]);
  const botOptions =
    tabCheck === "setup"
      ? bots.map((bot) => {
          return (
            <Dropdown.Item key={bot.id} onSelect={() => setSelectedBot(bot)}>
              {bot.botName}
            </Dropdown.Item>
          );
        })
      : [];
  return (
    <Fragment>
      <Modal show={open}>
        <Modal.Header>
          <Modal.Title>Select The Bot To Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            After making changes to a bot, you can test them out before deciding
            to publish to the live version of your bot.
          </p>
          <Dropdown>
            <Dropdown.Toggle
              style={{ width: "100%" }}
              variant="success"
              id="dropdown-basic"
            >
              {selectedBot.botName}
            </Dropdown.Toggle>

            <Dropdown.Menu>{botOptions}</Dropdown.Menu>
          </Dropdown>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpen(false)} variant="secondary">
            Close
          </Button>
          <Button
            href={`${process.env.REACT_APP_BASE_BOT_URL}/${companyId}/${selectedBot.id}?preview=true`}
            target={"_blank"}
            variant="primary"
          >
            Go To Preview
          </Button>
        </Modal.Footer>
      </Modal>
      <div
        className="header navbar"
        style={{
          display: "inline-block",
          background: "#4E7CFF",
        }}
      >
        <CompanySelector branches={branches} />

        {tabCheck === "setup" ? (
          <Fragment>
            <PublishButton />
            <Button
              style={{
                backgroundColor: "#35B459",
                borderColor: "black",
                borderRadius: 3,
                marginRight: 15,
              }}
              className="float-right"
              onClick={() => setOpen(true)}
            >
              Preview Bot
            </Button>

            <div className="brand visible-xs"></div>
          </Fragment>
        ) : null}
      </div>
    </Fragment>
  );
};
