import React, { useState, useEffect, useCallback, useRef } from "react";
import ReactCrop from "react-image-crop";

export default function ImageCropper({
  orgnlImage,
  filename,
  aspect,
  setCroppedImage,
  circular,
}) {
  const [crop, updateCrop] = useState({
    unit: "%",
    width: 100,
    borderRadius: "50%",
    height: aspect ? "" : 100,
    aspect: aspect || "",
  });
  const imgRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const onLoad = useCallback((img) => {
    imgRef.current = img;
    updateCrop((prev) => {
      return {
        ...prev,
        aspect: img.naturalWidth / img.naturalHeight,
      };
    });
    return false;
  }, []);

  useEffect(() => {
    if (!imgRef.current || !completedCrop) {
      return;
    }
    const image = imgRef.current;
    const crop = completedCrop;
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = Math.ceil(crop.width * scaleX * pixelRatio);
    canvas.height = Math.ceil(crop.height * scaleY * pixelRatio);

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    if (imgRef.current && crop.width && crop.height) {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = filename;
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setCroppedImage(reader.result);
        });
        reader.readAsDataURL(blob);
      }, "image/jpeg");
    }
  }, [completedCrop, filename, setCroppedImage]);

  return (
    <div>
      {orgnlImage && (
        <ReactCrop
          src={orgnlImage}
          crop={crop}
          ruleOfThirds
          circularCrop={circular}
          onImageLoaded={onLoad}
          onComplete={(crop) => setCompletedCrop(crop)}
          onChange={(updatedCrop) => {
            updateCrop(updatedCrop);
          }}
        />
      )}
    </div>
  );
}
