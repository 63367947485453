import { useRef } from "react";

const DisplayImage = (props) => {
  const { filename, id, onChange, allowableContentType } = props;
  const hiddenFileInput = useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  return (
    <div>
      <input
        ref={hiddenFileInput}
        type="file"
        style={{
          display: "none",
        }}
        accept={allowableContentType}
        onChange={(e) => onChange(e)}
      />
      {/* biome-ignore lint/a11y/useButtonType: <we don't need this> */}
      <button
        onClick={() => handleClick()}
        className={`branding-image-button branding-image-button-${id}`}
      >
        <img
          style={{
            border: "1px solid #ddd",
            padding: " 5px",
            "&:hover": {
              background: "#efefef",
            },
          }}
          className={`branding-image ${id}`}
          src={filename}
          alt="target"
        />
        <div>+</div>
      </button>
    </div>
  );
};

export default DisplayImage;
