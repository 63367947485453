import React, { useEffect, useState } from "react";
import { Container, Button } from "aws-amplify-react";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import ImageCropper from "../ImageCropper.jsx";
import PropTypes from "prop-types";
import "react-image-crop/dist/ReactCrop.css";
import "react-toastify/dist/ReactToastify.css";
import DisplayVideo from "./DisplayVideo.js";
import DisplayImage from "./DisplayImage.js";

export default function S3ImageUploader(props) {
  const { filename, image, uploaded, aspect, type, allowableContentType } =
    props;

  const [targetImage, setTargetImage] = useState(null);
  const toastId = React.useRef(null);
  const [tisVideo, setTisVideo] = useState(false);
  const [croppingImage, toggleCroppingImage] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [originalFile, setOriginalFile] = useState(null);
  useEffect(() => {
    let timage = image || null;
    let isVideo = false;
    if (timage) {
      timage = timage.split("?")[0];
      if (timage.indexOf(".mp4") !== -1) {
        isVideo = true;
      }
    }
    setTargetImage(timage);
    setTisVideo(isVideo);
  }, [image]);
  const uploadFile = async (originalFile, croppedImage) => {
    const name = originalFile.name;
    const lastDot = name.lastIndexOf(".");
    const ext = name.substring(lastDot + 1);
    const contentType =
      originalFile.name.indexOf(".mp4") !== -1 ? "video/mp4" : `image/${ext}`;
    if (
      await uploaded(
        type,
        filename,
        croppedImage || originalFile,
        contentType,
        ext,
      )
    ) {
      setTargetImage(croppedImage);
      setOriginalFile(null);
    } else {
      setTargetImage(null);
      const input = document.getElementById(`${filename}-input`);
      if (input) {
        input.value = "";
      }
    }
  };

  const onChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      if (file.name.indexOf(".mp4") !== -1) {
        uploadFile(file);
      } else {
        if (file.size / 1024 ** 2 > 1) {
          if (!toast.isActive(toastId)) {
            toastId.current = toast(
              "File is too large. Please select an image 1MB or less in size.",
            );
          }
          if (document.getElementById(`${filename}-input`)) {
            document.getElementById(`${filename}-input`).value = "";
          }

          return;
        }
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImageToCrop(reader.result);
          toggleCroppingImage(true);
        });
        reader.readAsDataURL(file);
        setOriginalFile(file);
      }
    }
  };

  const removeImage = (contentType) => {
    setTargetImage(null);
    uploaded(type, null, contentType, true);
  };

  const displayElement = () => {
    if (!tisVideo) {
      return (
        <DisplayImage
          {...props}
          contentType="image/png"
          removeImage={removeImage}
          allowableContentType={allowableContentType}
          onChange={onChange}
          id={filename}
          filename={targetImage}
          label={"Image"}
        />
      );
    }
    return (
      <DisplayVideo
        {...props}
        contentType="video/mp4"
        removeImage={removeImage}
        filename={targetImage}
        label={"Video"}
      />
    );
  };
  return (
    <Container>
      {targetImage ? (
        displayElement()
      ) : (
        <div>
          <input
            id={`${filename}-input`}
            type="file"
            accept={allowableContentType}
            onChange={(e) => onChange(e)}
          />
        </div>
      )}
      <ToastContainer />
      <Modal show={croppingImage} onHide={() => toggleCroppingImage(false)}>
        <Modal.Body>
          <ImageCropper
            aspect={aspect}
            filename={filename}
            circular={props.circular}
            orgnlImage={imageToCrop}
            setCroppedImage={setCroppedImage}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setImageToCrop(null);
              setCroppedImage(null);
              toggleCroppingImage(false);
              const input = document.getElementById(`${filename}-input`);
              if (input) {
                input.value = "";
              }
            }}
          >
            Close
          </Button>
          <Button
            disabled={!croppedImage}
            onClick={() => {
              uploadFile(originalFile, croppedImage);
              setImageToCrop(null);
              setCroppedImage(null);
              toggleCroppingImage(false);
            }}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

S3ImageUploader.propTypes = {
  uploaded: PropTypes.func,
  type: PropTypes.string,
};
