import { Link } from "react-router-dom";
import "./resetPassword.css";
import ButtonLoad from "../ButtonLoad";

import React, { useState } from "react";
import useQueryParams from "../../hooks/router/useQueryParams";
import useResetPassword from "../../hooks/auth/useResetPassword";
import ALLOWED_PASSWORD_CHARACTERS from "../../constants";

const ResetPassword = () => {
  const queryCode = useQueryParams().get("code");
  const queryEmail = useQueryParams().get("email");
  // replace spaces with +
  const formattedQueryEmail = queryEmail
    ? queryEmail.replace(" ", "+").trim()
    : queryEmail;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const {
    errorMessage,
    setErrorMessage,
    isResetting,
    resetComplete,
    resetPasswordWithCode,
  } = useResetPassword();

  const handleResetPassword = async (event) => {
    event.preventDefault();

    const hasValidCharacters = [...password].every((char) =>
      ALLOWED_PASSWORD_CHARACTERS.includes(char),
    );

    if (!hasValidCharacters) {
      return setErrorMessage(
        "Password does not conform to policy: Invalid characters",
      );
    }

    if (password !== confirmPassword) {
      return setErrorMessage("Passwords do not match");
    }
    if (password?.trim().length <= 1) {
      return setErrorMessage(
        "Password does not conform to policy: Password not long enough",
      );
    }

    await resetPasswordWithCode({
      email: formattedQueryEmail,
      code: queryCode,
      password,
    });
  };

  return (
    <div className="auth-container">
      <div className="auth-inner-container">
        <img className="auth-logo" src="/servicebot-logo-small.png" alt="" />
        {
          // If resetComplete is true, show the success message
          resetComplete ? (
            <>
              <div className="auth-intro-text">
                Password reset successful. Please login with your new password
              </div>
              <div className="resend-code">
                <Link className="link-success" to="/">
                  Back to login
                </Link>
              </div>
            </>
          ) : // Otherwise, show the reset password form
          !queryCode || !queryEmail ? (
            <>
              <div className="auth-intro-text">
                Please check your email for the reset code
              </div>
              <div className="resend-code">
                Didn&apos;t get an email?{" "}
                <Link className="link-success" to="/forgot-password">
                  Resend code
                </Link>
              </div>
            </>
          ) : (
            <>
              <div>Resetting password for email {queryEmail}</div>

              <form className="form" onSubmit={handleResetPassword}>
                <input
                  className="input"
                  name="password"
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => {
                    if (errorMessage) setErrorMessage("");
                    setPassword(e.target.value.trim());
                  }}
                  placeholder="Enter Password"
                />
                <input
                  className="input"
                  name="confirmPassword"
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => {
                    if (errorMessage) setErrorMessage("");
                    setConfirmPassword(e.target.value.trim());
                  }}
                  placeholder="Confirm Password"
                />
                {errorMessage && <p className="error">{errorMessage}</p>}
                <ButtonLoad
                  type="submit"
                  isLoading={isResetting}
                  disabled={!password.length || !confirmPassword.length}
                  text="Reset Password"
                />
                <div className="resend-code">
                  Didn&apos;t get an email?{" "}
                  <Link className="link-success" to="/forgot-password">
                    Resend code
                  </Link>
                </div>
              </form>
            </>
          )
        }
      </div>
    </div>
  );
};

export default ResetPassword;
