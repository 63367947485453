export class Metric {
  constructor(
    value,
    metric = 0,
    cols = 3,
    bgColor = "#ffff00",
    txtColor = "#ffffff",
    link = null,
  ) {
    this.value = value;
    this.label = metric;
    this.cols = cols;
    this.bgColor = bgColor;
    this.txtColor = txtColor;
    this.link = link;
  }
}
