import { API } from "aws-amplify";

import { useState } from "react";
import { toast } from "react-toastify";

const useCreateUser = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const createUser = async ({ email, name, roles, companyId }) => {
    setLoading(true);
    setUser(null);

    try {
      const createdUser = await API.post(
        "dashboardapi",
        "/v1/management/add-user",
        {
          headers: {
            "Company-Id": companyId,
          },
          body: {
            email: email.toLowerCase(),
            name,
            roles: JSON.stringify(roles),
          },
        },
      );

      if (createdUser?.error) {
        throw Error(createdUser.message);
      }
      setUser(createdUser);
      toast.success("User created successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return createdUser;
    } catch (err) {
      toast.error(
        err.response?.message ||
          err.response?.data?.message ||
          "Something went wrong when creating user",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    user,
    loading,
    createUser,
  };
};

export default useCreateUser;
