import { useState, useCallback } from "react";
import { toast } from "react-toastify";
import { API } from "aws-amplify";

// Custom Hook for Branding Update
const useUpdateBrandingImages = (
  activeCompanySnapshot,
  branding,
  setBranding,
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateBrandingImages = useCallback(
    // biome-ignore lint/style/useDefaultParameterLast: <explanation>
    async (type, filename, path, contentType = "image/*", ext) => {
      const payload = { ...branding, aboutUsAssetType: contentType };
      const data = {
        payload,
        filename,
        file: path,
        type,
        contentType,
        ext,
      };

      if (!payload.id) {
        setError("No payload ID found");
        return false;
      }

      setLoading(true);
      setError(null);
      let success = false;

      try {
        const updatedPayload = await API.post(
          "dashboardapi",
          "/v1/companyBranding",
          {
            headers: {
              "Company-Id": activeCompanySnapshot?.companyId,
            },
            body: { data },
          },
        );

        setBranding({ ...branding, ...updatedPayload });
        success = true;
        toast("Updated branding images", {
          className: "rotateY animated",
          autoClose: 3000,
        });
      } catch (error) {
        // Error toast
        toast(`Error uploading ${type} file: ${error.message}`, {
          className: "rotateY animated",
          type: toast.TYPE.ERROR,
          autoClose: 3000,
        });
        setError(error.message);
      } finally {
        setLoading(false);
      }

      return success;
    },
    [activeCompanySnapshot, branding, setBranding],
  );

  return { updateBrandingImages, loading, error };
};

export default useUpdateBrandingImages;
