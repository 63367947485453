import React, { useEffect, useState, useContext } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

import * as _ from "lodash";

import "react-toastify/dist/ReactToastify.css";
import "./SortableTable.css";

import { orderData, onDragEnd } from "./Functions";

import DragAndDropContainer from "./DragAndDropContainer";
import Images from "./Images/Images";
import OtherDraggable from "./Draggables/OtherDraggable";

import StyleContext from "../../styling/styleContext";

const SortableTableContainer = ({
  data,
  step,
  updateBotQuestions,
  type,
  botId,
}) => {
  const [state, setState] = useState([]);
  const [active, setActive] = useState();
  const [hasOption, setHasOption] = useState(false);
  const [hasConditional, setHasConditional] = useState(false);
  const [arr, setArr] = useState([]);

  const notify = (val) => toast(val);

  const saveTable = () => {
    setActive(false);
  };

  useEffect(() => {
    const newData = orderData(data, setArr, setHasOption, setHasConditional);
    setState(newData);
  }, [data]);

  console.log({ data });

  const setButton = () => {
    setActive(true);
  };

  const updateData = (original, newOrder) => {
    const filtered = original.filter(
      ({ id }) => id && newOrder.find((n) => id === n.id),
    );
    const final = [...newOrder, ...filtered];
    return final;
  };

  const saveStep = async (data, state) => {
    console.log({ new: updateData(data, state) });

    console.log("saving", step);
    console.log({ botId });
  };

  const handleOnDragEnd = (result) => {
    if (result.destination.index === 0) return false;

    const items = onDragEnd(result, state);

    setState(items);
    setActive(true);
    saveStep(data, items);
    console.log({ data, items });
  };

  const props = {
    data,
    state,
    active,
    hasOption,
    hasConditional,
    arr,
    notify,
    saveTable,
    setButton,
    handleOnDragEnd,
    updateBotQuestions,
    type,
    botId,
    step,
  };

  return <SortableTable {...props} />;
};

const SortableTable = ({
  data,
  state,
  active,
  saveTable,
  handleOnDragEnd,
  updateBotQuestions,
  type,
  botId,
  step,
  refetch,
}) => {
  const [toggled, setToggled] = useState(false);

  const style = useContext(StyleContext);

  const conditionals = data.reduce((allConditionals, { conditionals }) => {
    console.log({ conditionals });
    if (conditionals) {
      allConditionals.push(
        ...conditionals.map(({ matches: label }) => ({ label })),
      );
    }
    return allConditionals;
  }, []);

  const triggers = data.reduce((allTriggers, { trigger: label }) => {
    if (label) {
      allTriggers.push({ label });
    }
    return allTriggers;
  }, []);

  const ImagesContainer = ({ openModal }) => (
    <Images
      data={data}
      openModal={openModal}
      toggled={toggled}
      setToggled={setToggled}
    />
  );

  return (
    <div className="App">
      <div>
        <DragAndDropContainer
          s={data}
          handleOnDragEnd={handleOnDragEnd}
          allSteps={state}
          refetch={refetch}
          updateBotQuestions={updateBotQuestions}
          type={type}
          botId={botId}
          Images={ImagesContainer}
        />
        <OtherDraggable step={step} />
      </div>
      {active ? (
        <Button
          style={style.buttonStyle("red")}
          data-testid="button-test"
          onClick={saveTable}
        >
          Save Table
        </Button>
      ) : null}
    </div>
  );
};

export default SortableTableContainer;
