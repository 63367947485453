import React, { useState } from "react";
import ButtonLoad from "../../ButtonLoad";
import { useSignIn } from "../../../hooks/auth/useSignIn";
import { Link } from "react-router-dom";

const SignInForm = ({ setIsNewPasswordRequired, setUser }) => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const { signIn, isSigningIn, errorMessage } = useSignIn();

  const handleSignIn = async (e) => {
    e.preventDefault();
    const { isNewPasswordRequired, user } = await signIn(email, password);
    if (isNewPasswordRequired) {
      setIsNewPasswordRequired(true);
    }
    if (user) {
      setUser(user);
    }
  };
  return (
    <>
      <div className="instructions">Log into your ServiceBot account</div>

      <form className="form" onSubmit={handleSignIn}>
        <input
          className="input"
          name="email"
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <input
          className="input"
          name="password"
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        {errorMessage && <p className="error">{errorMessage}</p>}
        <Link to="/forgot-password">Forgot password?</Link>
        <ButtonLoad
          type="submit"
          isLoading={isSigningIn}
          disabled={!password.length || !email.length}
          text="Log In"
        />
        <div className="resend-code">
          Don&apos;t have an account?{" "}
          <a
            className="link-success"
            target="_blank"
            href="https://goservicebot.com/"
            rel="noreferrer"
          >
            Get Started
          </a>
        </div>
      </form>
    </>
  );
};

export default SignInForm;
