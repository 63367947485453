import React from "react";

import { Link } from "react-router-dom";

import { Auth } from "aws-amplify";

export const SideBarPanel = (props) => {
  return (
    <div className="sidebar-panel offscreen-left">
      <div className="brand" />
      <ul className="quick-launch-apps hide">
        <li>
          {/* biome-ignore lint/a11y/useValidAnchor: <explanation> */}
          <a href="#">
            <img src={"/lawnbot.png"} style={{ width: 50 }} alt="" />
            <span
              className="app-title"
              style={{
                fontFamily: "VAG-Rounded-Std",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: 15,
              }}
            >
              Servicebot
            </span>
          </a>
        </li>
        <li>
          <Link to={"/measurit"}>
            <img src={"/measurit.png"} style={{ width: 54 }} alt="" />
            <span
              className="app-title"
              style={{
                fontFamily: "VAG-Rounded-Std",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: 15,
              }}
            >
              MeasurIt
            </span>
          </Link>
        </li>
        <li>
          <a
            href="https://lawnbot.biz/review/"
            target={"_blank"}
            rel="noreferrer"
          >
            <img src={"/reviewbot.png"} style={{ width: 50 }} alt="" />
            <span
              className="app-title"
              style={{
                fontFamily: "VAG-Rounded-Std",
                fontWeight: "bold",
                fontStyle: "normal",
                fontSize: 15,
              }}
            >
              Reviewbot
            </span>
          </a>
        </li>
      </ul>
      {/* <!-- main navigation --> */}
      <nav role="navigation">
        <ul className="nav">
          {/* <!-- dashboard --> */}
          <li>
            <div
              style={{
                position: "relative",
                padding: "0.8125rem 1.563rem",
                display: "block",
                textAlign: "left",
                lineHeight: 1,
                ".transition": "background 150ms, color 150ms",
              }}
            >
              <i className="icon-compass" />
              <Link to="/">
                <span>Dashboard</span>
              </Link>
            </div>
          </li>
          {/* <!-- /dashboard --> */}

          {/* <!-- quotes --> */}
          <li>
            <div
              style={{
                position: "relative",
                padding: "0.8125rem 1.563rem",
                display: "block",
                textAlign: "left",
                lineHeight: 1,
                ".transition": "background 150ms, color 150ms",
              }}
            >
              <i className="icon-docs" />
              <Link to="/quotes">
                <span>Quotes</span>
              </Link>
            </div>

            <ul className="sub-menu">
              <li>
                <div
                  style={{
                    position: "relative",
                    padding: "0.8125rem 1.563rem",
                    display: "block",
                    textAlign: "left",
                    lineHeight: 1,
                    ".transition": "background 150ms, color 150ms",
                  }}
                >
                  <Link to="/quotes?filter=all">
                    <span>All</span>
                  </Link>
                </div>
              </li>
              <li>
                <div
                  style={{
                    position: "relative",
                    padding: "0.8125rem 1.563rem",
                    display: "block",
                    textAlign: "left",
                    lineHeight: 1,
                    ".transition": "background 150ms, color 150ms",
                  }}
                >
                  <Link to="/quotes?filter=sales">
                    <span>Sales</span>
                  </Link>
                </div>
              </li>
              <li>
                <div
                  style={{
                    position: "relative",
                    padding: "0.8125rem 1.563rem",
                    display: "block",
                    textAlign: "left",
                    lineHeight: 1,
                    ".transition": "background 150ms, color 150ms",
                  }}
                >
                  <Link to="/quotes?filter=leads">
                    <span>Leads</span>
                  </Link>
                </div>
              </li>
            </ul>
          </li>

          {/* <!-- ui --> */}
          {props.isAdmin && (
            <>
              <li>
                <div
                  style={{
                    position: "relative",
                    padding: "0.8125rem 1.563rem",
                    display: "block",
                    textAlign: "left",
                    lineHeight: 1,
                    ".transition": "background 150ms, color 150ms",
                  }}
                >
                  <i className="icon-settings" />
                  <Link to="/settings">
                    <span>Settings</span>
                  </Link>
                </div>
              </li>
              <li>
                <div
                  style={{
                    position: "relative",
                    padding: "0.8125rem 1.563rem",
                    display: "block",
                    textAlign: "left",
                    lineHeight: 1,
                    ".transition": "background 150ms, color 150ms",
                  }}
                >
                  <i className="icon-settings" />
                  <Link to="/setup">
                    <span>Setup</span>
                  </Link>
                </div>
              </li>
            </>
          )}
          <li>
            <div
              style={{
                position: "relative",
                padding: "0.8125rem 1.563rem",
                display: "block",
                textAlign: "left",
                lineHeight: 1,
                ".transition": "background 150ms, color 150ms",
              }}
            >
              <i className="icon-user-unfollow" />
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  e.bubbles = false;

                  Auth.signOut().then((val) => {
                    window.location = "";
                  });
                }}
              >
                <span>Signout</span>
              </Link>
            </div>
          </li>
        </ul>
      </nav>
      {/* <!-- /main navigation --> */}
      <div
        className="row"
        style={{
          bottom: 0,
          position: "absolute",
          marginBottom: 20,
          padding: 20,
        }}
      >
        <div className="col-span-12">
          {props.branches?.length === 1 ? (
            <img
              style={{ width: "100%" }}
              src={props.branches[0].logoUrl}
              alt=""
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
