import React from "react";
import PropTypes from "prop-types";

export const Footer = (props) => {
  return null;
  // return (
  //   <footer
  //     className="content-footer"
  //     style={{ marginLeft: 0, marginRight: 0 }}
  //   >
  //     <nav className="hidden-xs">
  //       <ul className="nav">
  //         <li>
  //           <a href="#">
  //             <span>About</span> Reactor
  //           </a>
  //         </li>
  //         <li>
  //           <a href="#">Privacy</a>
  //         </li>
  //         <li>
  //           <a href="#">Terms</a>
  //         </li>
  //         <li>
  //           <a href="#">Help</a>
  //         </li>
  //       </ul>
  //     </nav>
  //   </footer>
  // );
};
