import { Link } from "react-router-dom";
import "./forgotPassword.css";
import ButtonLoad from "../ButtonLoad";
import { Button } from "react-bootstrap";

import React, { useState } from "react";
import useForgotPassword from "../../hooks/auth/useForgotPassword";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const {
    error,
    codeSent,
    setCodeSent,
    isSendingCode,
    receivePasswordResetCode,
  } = useForgotPassword();

  const handleSendCodeClick = async (event) => {
    event.preventDefault();

    receivePasswordResetCode(email);
  };

  const resendCode = async () => {
    setCodeSent(false);
  };

  return (
    <div className="auth-container">
      <div className="auth-inner-container">
        <img className="auth-logo" src="/servicebot-logo-small.png" alt="" />
        {codeSent ? (
          <>
            <div className="instructions-success">
              Please check your email for the password reset link.
            </div>
            <div className="resend-code">
              <span> Didn&apos;t get an email?</span>{" "}
              <Button
                variant="link"
                onClick={resendCode}
                className="resend-code-click"
                to="/forgot-password"
              >
                Resend code
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="auth-intro-text">Enter your Email</div>

            <form className="form" onSubmit={handleSendCodeClick}>
              <input
                className="input"
                name="email"
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
              {error && <p className="error">{error}</p>}

              <ButtonLoad
                type="submit"
                isLoading={isSendingCode}
                disabled={!email.length}
                className="signIn-button"
                text="Send Code"
              />
              <Link className="link-back" to="/">
                Back to login
              </Link>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
