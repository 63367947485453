import React from "react";
import Spinner from "react-bootstrap/Spinner";
import "./ButtonLoader.css";

export default function ButtonLoad({
  isLoading,
  className = "",
  disabled = false,
  text = "",
  ...props
}) {
  return (
    <button
      className="buttonLoader"
      disabled={disabled || isLoading}
      {...props}
    >
      <div className="Text">{text}</div>
      {isLoading && (
        <Spinner className="spinner" animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      )}
      {props.children}
    </button>
  );
}
