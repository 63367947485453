import React, { useState, useContext } from "react";
import { FormControl, Form, Button, Card } from "react-bootstrap";
import StyleContext from "../../../../styling/styleContext";
const HtmlForm = ({ botStep, setBotStep }) => {
  const style = useContext(StyleContext);
  const handleChange = (html) => {
    setBotStep({
      ...botStep,
      component: {
        type: "html",
        html,
      },
    });
  };
  return (
    <>
      {/* <Form.Label className='modal-header' style={{}}><h5>Html</h5></Form.Label> */}
      <h6 style={{ ...style.styleSizing, color: style.colors.blue }}>Html</h6>
      <FormControl
        className="modal-input"
        style={{ height: 150 }}
        as="textarea"
        aria-label="With textarea"
        onChange={(e) => handleChange(e.target.value)}
        type="text"
        placeholder="Insert your own HTML"
      />
    </>
  );
};

export default HtmlForm;
