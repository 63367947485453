import { Auth } from "aws-amplify";

import { useState } from "react";
import { toast } from "react-toastify";
import DashboardApi from "../../utils/api/dashboardApi";

// TODO: merge this hook with useForgotPassword
const useResetUserPassword = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const resetUserPassword = async (email) => {
    setLoading(true);
    setData(null);

    try {
      const response = await DashboardApi.post("/v2/auth/reset-password", {
        email: email.toLowerCase(),
      });
      await Auth.forgotPassword(email);

      toast.success("Password reset successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setData(response.data);
      return true;
    } catch (err) {
      toast.error(
        err.message || "An error occurred while resetting the password",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
      return false;
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading,
    resetUserPassword,
  };
};

export default useResetUserPassword;
