import React from "react";
import PropTypes from "prop-types";
import { KpiBlockWidget } from "./kpi-block";

export const KpiRow = (props) => {
  return (
    // <div className="card no-border bg-white  m-x-n-g">
    <div className="row">
      {props.metrics.map((i) => {
        const key = "metric-block-" + Math.floor(Math.random() * 10000000);
        return <KpiBlockWidget key={key} {...i} />;
      })}
    </div>
    // </div>
  );
};

KpiRow.propTypes = {
  metrics: PropTypes.array.isRequired,
};
