import React, { useState, useEffect, useCallback, useRef } from "react";

import { TablePagination } from "../widgets/tables/table-pagination";
import { API } from "aws-amplify";
import { QuoteTable } from "../widgets/tables/quote-table";

import * as _ from "lodash";
import * as moment from "moment";

import { useParams } from "react-router-dom";
import { Button } from "reactstrap";
import { FilterWidget } from "../widgets/filter-widget";
import { Spinner } from "react-bootstrap";

import LoadingOverlay from "react-loading-overlay";
import "./quotes-list.css";
import { debounce, reverse } from "lodash";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../components/CompanySelector.jsx";
const formatQuotes = (quotesArray) => {
  const newArray = _.reverse(
    _.sortBy(
      _.flatten(
        quotesArray.map((ii) => {
          const marker = {
            ...ii.address,
            highlighted: false,
            coords: ii.address.location
              ? {
                  lat: ii.address.location.lat,
                  lng: ii.address.location.lon,
                }
              : { lat: null, lng: null },
          };

          return {
            ...ii,
            customer: ii.address.customer,
            address: ii.address.address,
            city: ii.address.city,
            state: ii.address.state,
            postalCode: ii.address.postalCode,
            addressId: ii.address.id,
            marker,
            createdAt: moment(ii.createdAt),
          };
        }),
      ),
      ["createdAt"],
    ),
  );
  return newArray;
};

const filterByTerm = (quotes, term) => {
  return [...quotes].filter((a) => {
    if (a.address?.toLowerCase().includes(term.toLowerCase())) return true;
    if (a.city?.toLowerCase().includes(term.toLowerCase())) return true;
    if (a.postalCode?.toLowerCase().includes(term.toLowerCase())) return true;
    if (a.customer.name?.toLowerCase().includes(term.toLowerCase()))
      return true;
    if (a.customer.phoneNumber?.toLowerCase().includes(term.toLowerCase()))
      return true;
    if (a.customer.email?.toLowerCase().includes(term.toLowerCase()))
      return true;
  });
};

// Hook
function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export const QuotesListScreen = () => {
  let { filterType } = useParams();

  if (filterType) {
    if (filterType === "sales") {
      filterType = "Only Sales";
    } else if (filterType === "leads") {
      filterType = "Only Leads";
    }
  }

  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const limit = 100;
  const [batchNumber, setBatchNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [nextToken, setNextToken] = useState({});
  const [isLoading, setLoading] = useState(true);
  const availbleFilters = ["Newest to oldest", "Only Sales", "Only Leads"];
  const [currentFilter, setCurrentFilter] = useState(
    filterType || availbleFilters[0],
  );

  const [filteredQuotes, setFilteredQuotes] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [allQuotes, setAllQuotes] = useState([]);

  const [items, setItems] = useState([]);

  const [term, setTerm] = useState("");

  const [mapKey, setMapKey] = useState(new Date().getTime());
  const [isLoadingMap, setIsLoadingMap] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);

  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState({ lat: 42.4977369, lng: -83.1736301 });
  const previousQuotes = usePrevious(allQuotes);
  const firstLoadChange = usePrevious(firstLoad);
  const activeCompanySnapshot = useSnapshot(activeCompanyState);

  const spliceArrayByLimit = useCallback(
    (arr, num) => {
      return num === 1
        ? [...arr].splice(0, limit)
        : [...arr].splice((num - 1) * limit, limit);
    },
    [limit],
  );

  // Wears a lot of hats, handles all of the filtering of the quotes data and map markers.
  // Was first mainly called for when a page was changed but is now also called when quotes data
  // is loading in and the quotes table needs to be reloaded with correct filtering.
  const changePage = useCallback(
    (num, propQuotes, propFilter, currentPage, value) => {
      let quotes = [...(propQuotes ? propQuotes : allQuotes)];
      const filter = propFilter || currentFilter;
      if (quotes.length) {
        if (num !== currentPage) {
          setIsLoadingMap(true);
        }

        if (value && value.trim() !== "") {
          quotes = filterByTerm(quotes, value);
        }
        if (filter) {
          if (filter === "Only Sales") {
            quotes = quotes.filter((i) => i.convertedSale);
          } else if (filter === "Only Leads") {
            quotes = quotes.filter((i) => !i.convertedSale);
          }
        }
        const pageCalc = Math.floor(quotes.length / limit) + 1;
        const currentPageQuotes = spliceArrayByLimit(quotes, num);
        const firstCoords = currentPageQuotes.find((quote) => {
          return quote.marker?.coords?.lat && quote.marker?.coords?.lng;
        });
        const mcenter = firstCoords ? firstCoords.marker.coords : {};
        const _mcenter = {
          lat: mcenter.lat,
          lng: mcenter.lng,
        };

        const currentPageItems = currentPageQuotes.map((quote) => {
          return { ...quote.marker, convertedSale: quote.convertedSale };
        });

        setPageCount(pageCalc);
        setFilteredQuotes(currentPageQuotes);
        setFilteredItems(currentPageItems);

        if (num !== currentPage) {
          setMapKey(new Date().getTime());
          if (firstCoords) {
            setCenter(_mcenter);
          }
        }

        setTimeout(() => {
          setIsLoadingMap(false);
        }, 600);
      }
    },
    [allQuotes, currentFilter, spliceArrayByLimit, limit],
  );
  // Required for when users start browsing the quotes data while still retreiving batches. Gets called
  // when more batches of quotes arrive and are added to the total quotes list
  useEffect(() => {
    if (previousQuotes && allQuotes.length !== previousQuotes.length) {
      changePage(currentPage, allQuotes, currentFilter, currentPage, term);
    }
  }, [
    allQuotes,
    limit,
    currentPage,
    currentFilter,
    changePage,
    previousQuotes,
    term,
  ]);

  const handleTermChange = (value, quotes) => {
    setTerm(value);
    const debounceTerm = debounce(() => {
      changePage(1, quotes, currentFilter, 1, value);
      setCurrentPage(1);
    }, 500);
    debounceTerm();
  };

  // Queries for the quotes data and sets our pagination bookmark, items, and quotes.
  const loadAddresses = useCallback(
    async (nextToken, firstLoad, paginationLimit) => {
      setLoading(true);
      const { results: quoteList, bookMark } = await API.get(
        "dashboardapi",
        `/v1/dashboard/list/quotes?bookMark=${JSON.stringify(
          nextToken,
        )}&paginationLimit=${paginationLimit}`,
        {
          headers: {
            "Company-Id": activeCompanySnapshot?.companyId,
          },
        },
      );
      if (quoteList.length) {
        setTotal((oldTotal) => oldTotal + quoteList.length);
        const formattedQuotes = formatQuotes(quoteList);
        setAllQuotes((oldQuotesArray) => {
          let all_arr = [...oldQuotesArray, ...formattedQuotes];
          all_arr = _.sortBy(all_arr, ["createdAt"]);
          reverse(all_arr);
          return all_arr;
        });
      }
      if (firstLoad) {
        setFirstLoad(false);
      }
      const hasBookMark = Object.values(bookMark).find((i) => i != null);
      if (!bookMark || !hasBookMark) {
        setLoading(false);
      } else {
        setBatchNumber((prevNum) => prevNum + 1);
      }
      setNextToken(bookMark);
      setLoading(false);
    },
    [activeCompanySnapshot?.companyId],
  );

  // only called after first batch of data is loaded. Required to set the quote page scrolling.
  useEffect(() => {
    if (!firstLoad && firstLoad !== firstLoadChange) {
      changePage(1, null, currentFilter, 0, term);
      setCurrentPage(1);
    }
  }, [firstLoad, changePage, firstLoadChange, term, currentFilter]);

  // starts the querying for the quotes data. Stops Running after all data has beeng retrieved.
  useEffect(() => {
    const hasNextToken = Object.values(nextToken).find((i) => i != null);
    if (hasNextToken || firstLoad) {
      loadAddresses(nextToken, firstLoad, limit);
    }
  }, [loadAddresses, firstLoad, nextToken, limit]);

  useEffect(() => {
    if (
      activeCompanySnapshot?.companyId ||
      activeCompanySnapshot?.companyId === ""
    ) {
      setCurrentPage(1);
      setBatchNumber(1);
      setAllQuotes([]);
      loadAddresses({}, false, limit);
    }
  }, [activeCompanySnapshot?.companyId]);

  const boundsChange = (coords) => {
    if (!coords) return;

    const _mcenter = {
      lat: (coords.ne.lat + coords.sw.lat) / 2,
      lng: (coords.ne.lng + coords.sw.lng) / 2,
    };

    setCenter(_mcenter);
    setZoom(coords.zoom);
  };

  const buttonStyle = {
    borderRadius: 10,
    fontFamily: "'Proxima Nova'",
    color: "black",
    width: 135,
    letterSpacing: 1,
    fontSize: 18,
    marginRight: 10,
  };

  const showMarker = (addressId) => {
    const nonTargets = filteredItems
      .filter((i) => i.id !== addressId)
      .map((i) => {
        return { ...i, highlighted: false };
      });
    const target = filteredItems.find((i) => i.id === addressId);
    target.highlighted = true;

    const updated = [...nonTargets, target];
    setFilteredItems(updated);
  };

  const highlightRow = (r) => {
    if (r) {
      window.jQuery.smoothScroll({
        scrollElement: window.jQuery("#quote_table"),
        scrollTarget: `#quote_${r.id}`,
      });

      const el = window.jQuery(`#quote_${r.id}`);
      const revert = el.css("background-color");
      el.css("background-color", "#FFC380cc");

      setTimeout(() => {
        el.css("background-color", revert);
      }, 2000);
    }
  };

  const filterChange = (val) => {
    setCurrentFilter(val);
    changePage(1, null, val);
    setCurrentPage(1);
  };
  return (
    <>
      <div className="page-title">
        <div className="title">Quotes</div>
        <div className="sub-title">All Quotes</div>
      </div>
      <div className="row">
        <div className="md-col-12">
          <div>
            <div style={{ display: "flex" }}>
              <input
                name={"search"}
                id="search_filter_bar"
                value={term}
                onChange={(e) => handleTermChange(e.target.value, allQuotes)}
                style={{
                  borderRadius: 20,
                  width: 400,
                  height: 43,
                  fill: "#FFFFFF",
                  // border: "#707070 1px solid",
                  padding: 10,
                  fontSize: 16,
                  color: "#A8A8A8",
                  border: "0px",
                  fontFamily: "'Proxima Nova'",
                  marginBottom: 10,
                }}
                placeholder={"Search for quote"}
              />
              <i
                className="fa fa-search icon"
                style={{
                  padding: 10,
                  minWidth: 40,
                  position: "relative",
                  left: -50,
                  fontSize: 16,
                }}
              />
              {isLoading && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Spinner className="spinner" animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                  <p
                    style={{ marginBottom: 0, marginLeft: 10 }}
                  >{`Loading group ${batchNumber} of quotes.`}</p>
                </div>
              )}
            </div>

            <div
              className="filter-buttons"
              style={{ marginBottom: 10, display: "inline-block" }}
            >
              <div
                style={{
                  fontFamily: "'PT Mono'",
                  fontWeight: "bold",
                  color: "black",
                  display: "inline-block",
                  fontSize: 20,
                  marginRight: 15,
                  marginTop: 5,
                }}
              >
                Filters
              </div>
              <Button
                style={{
                  backgroundColor: "#FCDC69",
                  ...buttonStyle,
                  fontFamily: "VAG-Rounded-Std",
                  height: 40,
                  fontWeight: "bold",
                }}
                onClick={() => filterChange("Only Leads")}
              >
                Leads
              </Button>
              <Button
                style={{
                  backgroundColor: "#22D4AE",
                  ...buttonStyle,
                  fontFamily: "VAG-Rounded-Std",
                  height: 40,
                  fontWeight: "bold",
                }}
                onClick={() => filterChange("Only Sales")}
              >
                Sales
              </Button>
              <Button
                style={{
                  ...buttonStyle,
                  backgroundColor: "#011507",
                  color: "white",
                  fontFamily: "VAG-Rounded-Std",
                  height: 40,
                  fontWeight: "bold",
                }}
                onClick={() => filterChange("Newest to oldest")}
              >
                All
              </Button>
            </div>

            <FilterWidget
              clickCb={filterChange}
              options={availbleFilters}
              title={currentFilter}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          {filteredItems.length && !isLoadingMap ? (
            <TablePagination
              currentPage={currentPage}
              total={total}
              pageCount={pageCount}
              changePage={(num) => {
                changePage(num, null, currentFilter, currentPage, term);
                setCurrentPage(num);
              }}
            />
          ) : null}
          {!filteredItems.length || isLoadingMap ? (
            <LoadingOverlay
              active={isLoadingMap}
              spinner={
                <>
                  <img src="/LB_loader.gif" style={{ width: 80 }} alt="" />
                  <br />
                </>
              }
              text="Loading your quotes..."
            >
              <div style={{ height: "80vh", width: "100%" }}>{null}</div>
            </LoadingOverlay>
          ) : (
            <div style={{ height: "80vh", overflow: "auto" }} id="quote_table">
              <LoadingOverlay
                active={isLoadingMap}
                spinner={<img alt="loader" src="/LB_loader.gif" />}
                key={"spinnerkey"}
              >
                <QuoteTable
                  key={"quotetablekey"}
                  rows={filteredQuotes}
                  showMarker={showMarker}
                />
              </LoadingOverlay>
            </div>
          )}
          {items.length && !isLoadingMap ? (
            <TablePagination
              currentPage={currentPage}
              total={total}
              pageCount={pageCount}
              changePage={(num) => {
                changePage(num, null, currentFilter, currentPage, term);
                setCurrentPage(num);
              }}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
