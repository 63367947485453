import React, { useState } from "react";
import "./RemoveButton.css";
import ConfirmModal from "../ConfirmModal";

export const RemoveButton = ({ onClick }) => {
  const [confirm, setConfirm] = useState(false);
  const openConfirm = () => setConfirm(true);
  const closeConfirm = () => setConfirm(false);
  const onConfirm = () => {
    closeConfirm();
    onClick();
  };
  const onCancel = () => {
    closeConfirm();
  };

  return (
    <>
      <i className="close-button" onClick={openConfirm}>
        <i className="fa fa-trash" onClick={openConfirm}></i>
      </i>
      <ConfirmModal
        onConfirm={onConfirm}
        onCancel={onCancel}
        show={confirm}
        hide={closeConfirm}
      />
    </>
  );
};
