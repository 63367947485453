import React, { useState } from "react";
import SignInForm from "./SignInForm";
import NewPasswordForm from "./NewPasswordForm";
import awsconfig from "../../../aws-exports.js";

import "./signIn.css";
import Amplify, { Auth } from "aws-amplify";

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

const SignInNew = () => {
  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false);
  const [user, setUser] = useState(null);

  return (
    <div className="auth-container">
      <div className="auth-inner-container">
        <img className="auth-logo" src="/servicebot-logo-small.png" alt="" />
        <>
          {isNewPasswordRequired ? (
            <NewPasswordForm user={user} />
          ) : (
            <SignInForm
              setIsNewPasswordRequired={setIsNewPasswordRequired}
              setUser={setUser}
            />
          )}
        </>
      </div>
    </div>
  );
};

export default SignInNew;
