import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import Amplify, { Auth } from "aws-amplify";
import awsExports from "./aws-exports.js";

import StyleContext from "./styling/styleContext";
import Styles from "./styling/style";

const custom = [
  {
    name: "dashboardapi",
    endpoint:
      process.env.REACT_APP_DASHBOARD_API ||
      "https://5gj2wrdfa5.execute-api.us-east-1.amazonaws.com/prod",
    // endpoint: "http://localhost:3006",
    custom_header: async () => {
      return {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      };
    },
  },
];
awsExports.aws_cloud_logic_custom = custom;

Amplify.configure(awsExports);
Auth.configure(awsExports);

ReactDOM.render(
  <React.StrictMode>
    <StyleContext.Provider value={Styles}>
      <App />
    </StyleContext.Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
