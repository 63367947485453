import React, { useState } from "react";
import ButtonLoad from "../../ButtonLoad";
import { useCreateNewPassword } from "../../../hooks/auth/useCreateNewPassword";
import ALLOWED_PASSWORD_CHARACTERS from "../../../constants";

const NewPasswordForm = ({ user }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { createNewPassword, isLoading, errorMessage, setErrorMessage } =
    useCreateNewPassword();

  const handleSignIn = async (e) => {
    e.preventDefault();

    const hasValidCharacters = [...password].every((char) =>
      ALLOWED_PASSWORD_CHARACTERS.includes(char),
    );

    if (!hasValidCharacters) {
      return setErrorMessage(
        "Password does not conform to policy: Invalid characters",
      );
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }
    if (password?.trim().length <= 1) {
      setErrorMessage(
        "Password does not conform to policy: Password not long enough",
      );
      return;
    }
    createNewPassword(user, password);
  };
  return (
    <>
      <div className="instructions">Create your new password</div>

      <form className="form" onSubmit={handleSignIn}>
        <input
          className="input"
          name="password"
          type="password"
          id="password"
          value={password}
          onChange={(e) => {
            if (errorMessage) setErrorMessage("");
            setPassword(e.target.value.trim());
          }}
          placeholder="Enter Password"
        />
        <input
          className="input"
          name="confirmPassword"
          type="password"
          id="confirmPassword"
          value={confirmPassword}
          onChange={(e) => {
            if (errorMessage) setErrorMessage("");
            setConfirmPassword(e.target.value.trim());
          }}
          placeholder="Confirm Password"
        />
        {errorMessage && <p className="error">{errorMessage}</p>}
        <ButtonLoad
          type="submit"
          isLoading={isLoading}
          disabled={!password.length || !confirmPassword.length}
          text="Change Password"
        />
      </form>
    </>
  );
};

export default NewPasswordForm;
