import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import jwt from "jsonwebtoken";

function useAuthUser() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        const session = await Auth.currentSession();
        const idToken = session.getIdToken().getJwtToken();
        const decodedToken = jwt.decode(idToken, { complete: true });

        setUser(decodedToken.payload);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  return { user, loading, error };
}

export default useAuthUser;
