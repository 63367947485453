import React, { useState, useContext } from "react";

import { Table, Button } from "reactstrap";

import StyleContext from "../../styling/styleContext";

import "./render-table.css";

export const ProgramTable = ({
  setBool,
  val = "Open",
  programs,
  handler,
  index,
  programName,
  programDescription,
  startMonth,
  endMonth,
  obj,
  program,
  openVersionControl,
}) => {
  const style = useContext(StyleContext);
  const [didMount, setDidMount] = useState(false);
  const [loading, setLoading] = useState(false);

  const fontStyle = {
    fontFamily: "VAG-Rounded-Std",
    // letterSpacing: "1px",
    // fontFamily: "Montserrat",
    fontSize: "11px",
    // padding: "15px",
    marginTop: 0,
    maxWidth: 500,
  };

  const thStyling = {
    ...style.styleSizing,
    fontSize: "1rem",
  };

  const tdStyling = {
    ...fontStyle,
    color: style.colors.blue,
    fontSize: 13,
  };

  return (
    <>
      <Table className="content-table" striped bordered hover>
        <thead>
          <tr style={{ background: style.colors.green }}>
            <th style={{ ...thStyling }}>
              {/* <th style={{ ...style.styleSizing }}> */}
              <i
                style={{ marginRight: 5 }}
                className="fa fa-info-circle"
                aria-hidden="true"
              ></i>
              Program Name
            </th>

            <th style={{ ...thStyling }}>
              {" "}
              <i
                style={{ marginRight: 5 }}
                className="fa fa-info-circle"
                aria-hidden="true"
              ></i>
              Program Description
            </th>
            <th style={{ ...thStyling }}>
              {" "}
              <i
                style={{ marginRight: 5 }}
                className="fa fa-calendar-plus-o"
                aria-hidden="true"
              ></i>
              Start Month
            </th>
            <th style={{ ...thStyling }}>
              <i
                style={{ marginRight: 5 }}
                className="fa fa-calendar-times-o"
                aria-hidden="true"
              ></i>
              End Month
            </th>
            <th style={{ ...thStyling }}>
              {" "}
              <i
                style={{ marginLeft: 5, padding: 5 }}
                className="fa fa-hand-pointer-o"
                aria-hidden="true"
              ></i>
              Settings
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {/* <td style={{ ...fontStyle, color: style.colors.blue }}>
              {programName}
            </td> */}
            <td style={{ ...tdStyling }}>{programName}</td>
            <td style={{ ...tdStyling }}>{programDescription}</td>
            <td style={{ ...tdStyling }}>{startMonth}</td>
            <td style={{ ...tdStyling }}>{endMonth}</td>
            <td style={{ ...tdStyling }}>
              <button
                style={style.submitButton(style.colors.green)}
                onClick={() => handler(index)}
              >
                Edit
              </button>
              <Button
                disabled={!program || !program.id}
                style={{
                  ...style.submitButton(style.colors.green),
                  marginTop: 20,
                }}
                onClick={() => {
                  openVersionControl("program", program.id);
                }}
              >
                Version Control
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default ProgramTable;
