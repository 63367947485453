const { currencies } = require("../currencies/currencies");

const getCurrencySymbol = (currencyName) => {
  let symbol = "$";
  if (currencyName) {
    const currency = currencies.find(
      (curr) => curr.name.toLowerCase() === currencyName.toLowerCase(),
    );
    if (currency) {
      symbol = currency.symbol;
    }
  }
  return symbol;
};

export default getCurrencySymbol;
