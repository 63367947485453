import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import StyleContext from "../../styling/styleContext";

const ConfirmModal = ({ show, hide, onConfirm, onCancel }) => {
  const style = useContext(StyleContext);
  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header closeButton>
        <h3 style={{ color: style.colors.blue }}>Item Removal</h3>
      </Modal.Header>

      <Modal.Body style={{ ...style.styleSizing, color: style.colors.blue }}>
        Are you sure you want to delete this item?
      </Modal.Body>

      <Modal.Footer>
        <Button
          style={{ ...style.submitButton(style.colors.green), height: 50 }}
          onClick={onCancel}
          variant="secondary"
        >
          Close
        </Button>
        <Button
          style={{ ...style.deleteButton, height: 50 }}
          onClick={onConfirm}
          variant="primary"
        >
          Confirm Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
