import React, { Fragment } from "react";
import { FormControl } from "react-bootstrap";

const SimpleOptions = ({ newOption, handleOptionsChange }) => {
  return (
    <Fragment>
      <FormControl
        className="modal-input"
        type="text"
        name="value"
        placeholder="new option value"
        value={(newOption.value, "dsa")}
        onChange={handleOptionsChange}
      />
      <FormControl
        className="modal-input"
        onChange={handleOptionsChange}
        type="text"
        name="text"
        placeholder="new option label"
        value={newOption.text}
      />
    </Fragment>
  );
};

export default SimpleOptions;
