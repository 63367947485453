import React from "react";
import { Route, Redirect } from "react-router-dom";

function AdminRoute(props) {
  const { component: Component, ...componentProps } = props;
  return (
    <Route
      {...componentProps}
      render={() => {
        return props.isAdmin ? <Component {...props} /> : <Redirect to="/" />;
      }}
    />
  );
}

export default AdminRoute;
