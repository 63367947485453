import React, { useContext } from "react";
import "./TriggerSelect.css";
import StyleContext from "../../../../styling/styleContext";
const TriggerSelect = ({ onChange, trigger, availableStepsByID }) => {
  const style = useContext(StyleContext);
  const selectedValue = trigger ? availableStepsByID[trigger].id : "";
  return (
    <div className="step-input">
      <h6 style={{ ...style.styleSizing, color: style.colors.blue }}>
        Select Or Create The Step Triggered:
      </h6>
      <div>
        <select
          className="modal-input"
          style={{ width: "100%", padding: 10 }}
          value={selectedValue}
          onChange={onChange}
        >
          <option key={"default-placeholder"} value="" disabled selected hidden>
            Please select an id...
          </option>
          {availableStepsByID &&
            Object.values(availableStepsByID).map((opt, index) => (
              <>
                <option key={`${opt.id}-${index}`} value={opt.id}>
                  {opt.displayName || opt.id}
                </option>
              </>
            ))}
          {/* <option id="custom" value="custom">
                Create new question type
              </option> */}
        </select>
        {/* {trigger === "custom" ? (
              <div>
                <br />
                <FormControl
                  onChange={(e) => setCustomField(e.target.value)}
                  type="text"
                  placeholder="Create the ID that will be launched"
                />
              </div>
            ) : null} */}
      </div>
    </div>
  );
};

export default TriggerSelect;
