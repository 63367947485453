import { useState } from "react";
import { Auth } from "aws-amplify";

const useResetPassword = () => {
  const [isResetting, setIsResetting] = useState(false);
  const [resetComplete, setResetComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const resetPasswordWithCode = async ({ email, code, password }) => {
    setIsResetting(true);

    try {
      await Auth.forgotPasswordSubmit(email.toLowerCase(), code, password);
      setResetComplete(true);
    } catch (error) {
      console.log("error resetting password:", error);
      switch (error.code) {
        case "CodeMismatchException":
          setErrorMessage("An invalid code was entered. Please try again.");
          break;
        case "LimitExceededException":
          setErrorMessage(
            "Reset attempts limit exceeded, please try after some time.",
          );
          break;
        case "ExpiredCodeException":
        case "InvalidPasswordException":
          setErrorMessage(error.message);
          break;
        default:
          setErrorMessage("An error occurred. Please try again.");
      }
    }
    setIsResetting(false);
  };

  return {
    isResetting,
    resetComplete,
    errorMessage,
    setErrorMessage,
    resetPasswordWithCode,
  };
};

export default useResetPassword;
