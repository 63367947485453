import { useCallback, useEffect, useState } from "react";
import DashboardApi from "../../utils/api/dashboardApi";

const useFetchActivityLogs = ({ clientId, activityType }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchActivityLogs = useCallback(async ({ clientId, activityType }) => {
    setLoading(true);
    setData([]);

    try {
      const response = await DashboardApi.get("/v2/activity-logs", {
        params: {
          clientId,
          activityType,
        },
      });

      setData(response.data);
    } catch (err) {
      console.log("An error occurred while fetching activity logs", err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchActivityLogs({ clientId, activityType });
  }, [activityType, clientId, fetchActivityLogs]);

  return {
    data,
    loading,
    fetchActivityLogs,
  };
};

export default useFetchActivityLogs;
