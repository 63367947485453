import { useState } from "react";
import { Auth } from "aws-amplify";
import DashboardApi from "../../utils/api/dashboardApi";

const useForgotPassword = () => {
  const [error, setError] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);

  const receivePasswordResetCode = async (email) => {
    setIsSendingCode(true);

    try {
      const response = await DashboardApi.post("/v2/auth/reset-password", {
        email: email.toLowerCase(),
      });

      if (response.status === 200) {
        await Auth.forgotPassword(email);
        setCodeSent(true);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError(error?.response?.data?.message || "An error occurred");
    }

    setIsSendingCode(false);
  };

  return {
    error,
    codeSent,
    setCodeSent,
    isSendingCode,
    receivePasswordResetCode,
  };
};

export default useForgotPassword;
