import { Button } from "react-bootstrap";

const DisplayVideo = (props) => {
  const { filename, removeImage, label, contentType } = props;

  return (
    <div>
      {/* biome-ignore lint/a11y/useMediaCaption: <explanation> */}
      <video width="500" controls>
        <source src={filename} type="video/mp4" />
      </video>
      <Button
        style={{ backgroundColor: "red" }}
        onClick={() => removeImage(contentType)}
        color="red"
        variant="contained"
      >
        Remove {label}
      </Button>
    </div>
  );
};
export default DisplayVideo;
