import { useState } from "react";
import { Auth } from "aws-amplify"; // or wherever Auth is imported from
import { useHistory } from "react-router-dom";

export const useCreateNewPassword = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const createNewPassword = async (user, newPassword) => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      await Auth.completeNewPassword(user, newPassword);
      history.go(0);
    } catch (e) {
      setErrorMessage(
        e.message || "An error occurred while setting the new password.",
      );
      console.log("e.code", e.code);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    createNewPassword,
    isLoading,
    errorMessage,
    setErrorMessage,
  };
};
