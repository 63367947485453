const { getServiceIcon } = require("./service-icons");
const _ = require("lodash");

export const formatNewQuote = (detail) => {
  if (detail.services?.application && detail.pricing) {
    let pricingString = "";
    let isPrepay = false;
    const icon = getServiceIcon(detail.serviceType);

    const serviceUrl = `https://sales.lawnbot.biz/assets/servicebot-icons/${icon}-selected.png`;
    const apps = detail.services.application.filter((t) => !t.tooLateToDo);

    const totalApps = apps.length;
    const totalSalesAmountWithTax = _.reduce(
      apps,
      (sum, i) => {
        sum += i.grossPrice + i.taxes;
        return sum;
      },
      0,
    );

    const appPricing = detail.pricing.find(
      (pp) => pp.frequencyType === "application",
    );
    if (appPricing) {
      switch (appPricing.pricingMethod) {
        case "monthly-payment":
          pricingString = `$${appPricing.servicePrice}/month for ${appPricing.pricingPeriods} months`;
          break;
        case "first-application-payment": {
          const appsClone = [...apps];
          if (appsClone.length) {
            const firstAppPrice = appsClone.shift().grossPrice;

            let restPrice = null;
            if (appsClone.length) {
              restPrice = appsClone[0].grossPrice;
            }

            if (
              restPrice &&
              firstAppPrice.toFixed(2) === restPrice.toFixed(2)
            ) {
              pricingString = `$${firstAppPrice.toFixed(2)} per application`;
            } else {
              pricingString = `$${firstAppPrice.toFixed(
                2,
              )} for the 1st application, regular price for remaining applications`;
            }
          } else {
            pricingString = `$${appPricing.servicePrice} per application`;
          }
          break;
        }
        case "total":
          pricingString = `$${appPricing.servicePrice} total`;
          isPrepay = true;
          break;
        default:
          pricingString = `$${appPricing.regularApplicationCost} per application`;
      }

      return {
        programName: detail.serviceName,
        pricingString,
        icon,
        serviceUrl,
        pCost: totalSalesAmountWithTax,
        isPrepay,
        applications: totalApps,
      };
    }
    // no app pricing
    return {
      programName: "unknown",
      pricingString: "",
      icon: "",
      serviceUrl: "",
      pCost: 0.0,
      isPrepay: false,
      note: "",
      applications: -1,
    };
  }
  return {
    programName: "unknown",
    pricingString: "",
    icon: "",
    serviceUrl: "",
    pCost: 0.0,
    isPrepay: false,
    note: "",
    applications: -1,
  };
};

export const getSummaryPricing = (details) => {
  if (details.length) {
    const first = details[0];
    if (first.services?.application && first.pricing) {
      return getNewSummaryPricing(details);
    }
    return getV1SummaryPricing(details);
  }
  return {
    grossSummaryPrice: 0.0,
    netSummaryPrice: 0.0,
    prePayDiscount: 0.0,
  };
};

export const getNewSummaryPricing = (details) => {
  const summary = details.map((detail) => {
    const apps = detail.services.application.filter((t) => !t.tooLateToDo);
    const totalApps = apps.length;
    const totalApplicationCost = _.reduce(
      apps,
      (sum, i) => {
        sum += i.grossPrice;
        return sum;
      },
      0,
    );
    const totalSalesAmountWithTax = _.reduce(
      apps,
      (sum, i) => {
        sum += i.grossPrice + i.taxes;
        return sum;
      },
      0,
    );
    let prePayPrice = -1;
    let prePayDiscount = 0.0;

    // get the prepay price via new services
    const prepayApps = detail.services.prePay.filter((t) => !t.tooLateToDo);
    if (prepayApps) {
      prePayPrice = _.reduce(
        prepayApps,
        (sum, i) => {
          sum += i.grossPrice;
          return sum;
        },
        0.0,
      );
    }

    if (prePayPrice !== -1) {
      prePayDiscount = totalApplicationCost - prePayPrice;
    }

    return {
      applications: totalApps,
      totalApplicationCost,
      totalSalesAmountWithTax,
      prePayDiscount,
    };
  });

  const { grossSummaryPrice, netSummaryPrice, prePayDiscount } = _.reduce(
    summary,
    (sum, i) => {
      sum.grossSummaryPrice += i.totalApplicationCost;
      sum.netSummaryPrice += i.totalSalesAmountWithTax;
      sum.prePayDiscount += i.prePayDiscount;
      return sum;
    },
    {
      grossSummaryPrice: 0.0,
      netSummaryPrice: 0.0,
      prePayDiscount: 0.0,
    },
  );

  return {
    grossSummaryPrice,
    netSummaryPrice,
    prePayDiscount,
  };
};

export const getV1SummaryPricing = (d) => {
  const totalProgramCost = _.reduce(
    d,
    (sum, n) => {
      if (n.firstAppPrice) {
        sum += n.firstAppPrice + (n.applications - 1) * n.applicationPrice;
      } else if (n.prePayPrice && n.term === "total") {
        sum += n.prePayPrice;
      } else {
        sum += Number.parseFloat(n.totalProgramCost);
      }
      return sum;
    },
    0,
  );

  const salesTaxAmount = _.reduce(
    d,
    (sum, n) => {
      let pCost = 0.0;
      if (n.firstAppPrice) {
        pCost += n.firstAppPrice + (n.applications - 1) * n.applicationPrice;
      } else if (n.prePayPrice && n.term === "total") {
        pCost += n.prePayPrice;
      } else if (n.term === "application") {
        pCost += n.applicationPrice;
      } else {
        pCost += Number.parseFloat(n.totalProgramCost);
      }

      if (n.salesTax > 0) {
        sum += pCost * (1 + n.salesTax / 100);
      } else {
        sum += pCost;
      }
      return sum;
    },
    0,
  );

  const prePayAndSave = _.reduce(
    d,
    (sum, n) => {
      if (n.term === "total") {
        sum += n.prePayPrice;
      } else {
        if (n.prePayDiscount && n.prePayDiscount > 0) {
          sum +=
            Number.parseFloat(n.totalProgramCost) -
            (Number.parseFloat(n.totalProgramCost) * n.prePayDiscount) / 100;
        } else {
          sum += Number.parseFloat(n.totalProgramCost);
        }
      }

      return sum;
    },
    0,
  );

  return {
    grossSummaryPrice: totalProgramCost,
    netSummaryPrice: salesTaxAmount,
    prePayDiscount: prePayAndSave,
  };
};

export const formatV1Quote = (detail) => {
  let isPrepay = false;
  const pricingString = detail.firstAppPrice
    ? `$${detail.firstAppPrice.toFixed(
        2,
      )} for the 1st application, $${detail.applicationPrice.toFixed(
        2,
      )} for remaining applications`
    : `$${detail.applicationPrice.toFixed(2)} for each application`;

  const icon = getServiceIcon(detail.serviceType);

  const serviceUrl = `https://sales.lawnbot.biz/assets/servicebot-icons/${icon}-selected.png`;
  let pCost = 0.0;
  let note = "";
  if (detail.firstAppPrice) {
    pCost =
      detail.firstAppPrice +
      (detail.applications - 1) * detail.applicationPrice;
  } else if (detail.prePayPrice && detail.term === "total") {
    pCost = detail.prePayPrice;
    isPrepay = true;
    note = `* pre-pay discount of ${detail.prePayDiscount}%`;
  } else {
    pCost = Number.parseFloat(detail.totalProgramCost);
  }

  return {
    programName: detail.programName,
    pricingString,
    icon,
    serviceUrl,
    pCost,
    isPrepay,
    note,
    applications: detail.applications,
  };
};
