import { useState } from "react";
import { Auth } from "aws-amplify"; // or wherever Auth is imported from
import { useHistory } from "react-router-dom";

export const useSignIn = () => {
  const history = useHistory();
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function signIn(email, password) {
    setIsSigningIn(true);
    setErrorMessage("");
    let isNewPasswordRequired = false;
    let user = null;
    try {
      const signedInUser = await Auth.signIn(email.toLowerCase(), password);
      user = signedInUser;
      if (signedInUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        isNewPasswordRequired = true;
      } else {
        history.go(0);
      }
    } catch (e) {
      setErrorMessage(e.message || "An error occurred during sign in.");
    } finally {
      setIsSigningIn(false);
    }
    return { isNewPasswordRequired, user };
  }

  return {
    signIn,
    isSigningIn,
    errorMessage,
  };
};
