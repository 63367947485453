import { Auth } from "aws-amplify";
import axios from "axios";

const DashboardApi = axios.create({
  baseURL: process.env.REACT_APP_DASHBOARD_API,
});

// Set up request interceptor to add Authorization header
DashboardApi.interceptors.request.use(
  async (config) => {
    try {
      console.log("Intercepting request to add Authorization header");

      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();

      config.headers.Authorization = `Bearer ${token}`;
    } catch (error) {
      console.error("Error fetching authentication token:", error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default DashboardApi;
