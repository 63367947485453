import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Accordion,
  Card,
  Spinner,
  AccordionContext,
  useAccordionToggle,
} from "react-bootstrap";
import { StyleContext } from "../../../styling/styleContext";

function CustomToggle({ children, eventKey, setToggle, toggle }) {
  const style = useContext(StyleContext);
  // const [toggle, setToggle] = useState(false);
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => setToggle(!toggle),
    // console.log("totally custom!")
  );

  return (
    <Button
      type="button"
      style={{
        ...style.styleSizing,
        backgroundColor: style.colors.green,
        fontSize: 16,
      }}
      onClick={decoratedOnClick}
    >
      {children}
    </Button>
  );
}

function AccordionComp({ accordions }) {
  const [toggled, setToggled] = useState(false);
  const style = useContext(StyleContext);
  const [activeKey, setActiveKey] = useState(
    sessionStorage.getItem("selected"),
  );

  const onSelect = (index) => {
    const selected = String(index);
    sessionStorage.setItem("selected", selected);
    // setActiveKey(selected);
    console.log({ selected });
  };

  return (
    <Accordion defaultActiveKey={activeKey} onSelect={onSelect}>
      <Card
        style={{
          textAlign: "left",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Card.Header style={{ ...style.styleSizing, fontWeight: 400 }}>
          <CustomToggle
            onClick={() => console.log("clicked")}
            style={{ background: style.colors.green }}
            eventKey="0"
            toggle={toggled}
            setToggle={setToggled}
          >
            {toggled ? "Close Additional Options" : "Open Additional Options"}
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse appear={true} eventKey="0">
          <Row style={{ width: "60%" }}>
            {accordions.map(({ value }, index) => {
              return (
                <Col key={`Options_Col_${index}`} xs="12" lg="6" md="6">
                  <Card style={{ width: "auto", height: "150px" }}>
                    <Card.Header
                      style={{
                        backgroundColor: style.colors.green,
                        color: "white",
                        fontFamily: style.styleSizing.fontFamily,
                      }}
                    >{`Option ${index + 1}`}</Card.Header>
                    <Card.Body>{value}</Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}
export const MessageStepDisplay = ({
  message,
  openModal,
  provided,
  containerStyle,
}) => {
  const style = useContext(StyleContext);
  return (
    <div style={{ width: "70%", ...containerStyle }}>
      <Card
        className="draggable-ids"
        data-testid={`options-test{$}index`}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <Card.Header
          style={{
            backgroundColor: style.colors.green,
            color: "white",
            fontWeight: 400,
            textAlign: "left",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                ...style.styleSizing,
              }}
              className="order"
            >
              <i className="fa fa-sort mr-3"></i>

              {message.displayName || message.id}
            </div>
            <i
              style={{ cursor: "pointer" }}
              onClick={openModal}
              className="icon-pencil"
            ></i>
          </div>
        </Card.Header>

        <Card.Body style={{ fontFamily: style.styleSizing.fontFamily }}>
          {message.options && <AccordionComp accordions={message.options} />}
          {!message.options && (
            <>
              <Card.Title></Card.Title>
              <Card.Text
                style={{
                  ...style.styleSizing,
                  fontWeight: 400,
                  color: style.colors.blue,
                }}
                aria-hidden="true"
              >
                {message.message || "not a message"}
              </Card.Text>
              <footer
                style={{ color: style.colors.green }}
                className="blockquote-footer"
              >
                {message.trigger || ""}
              </footer>
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};
