import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";

import { getServiceIcon } from "../utils/service-icons";
import { formatV1Quote } from "../utils/quote.format";
import getCurrencySymbol from "../utils/get-currency-symbol";

const {
  priceCalculations,
  installments,
  offers,
} = require("@workwave_it/lawnbot-pricing-calculations");

export const QuoteTabsWidget = (props) => {
  const [quote, setQuote] = useState(null);
  const [details, setDetails] = useState([]);
  const [dontTax, setDontTax] = useState(false);
  const [humanAnswers, setHumanAnswers] = useState({});

  useEffect(() => {
    const run = async () => {
      const q = { ...props.quote };
      try {
        q.selectedPrograms = q.selectedPrograms
          ? JSON.parse(q.selectedPrograms)
          : [];
      } catch (e) {
        q.selectedPrograms = q.selectedPrograms ? q.selectedPrograms : [];
      }

      try {
        q.recommendedProgams = q.recommendedProgams
          ? JSON.parse(q.recommendedProgams)
          : [];
      } catch (e) {
        q.recommendedProgams = q.recommendedProgams ? q.recommendedProgams : [];
      }

      try {
        q.serviceTypes = q.serviceTypes ? JSON.parse(q.serviceTypes) : [];
      } catch (e) {
        q.serviceTypes = q.serviceTypes ? q.serviceTypes : [];
      }

      try {
        q.answers = q.answers ? JSON.parse(q.answers) : {};
      } catch (e) {
        q.answers = q.answers ? q.answers : {};
      }
      if (q.selectedPrograms.length) {
        q.selectedPrograms = q.selectedPrograms.map((p) => {
          const newP = q.recommendedProgams.find(
            (f) => f.serviceName === p.programName,
          );
          if (newP) {
            newP.programName = newP.serviceName;
          }

          if (!p.services) {
            if (newP) {
              return newP;
            }
            return p;
          }
          if (
            (!p.offers && newP && newP.offers) ||
            (p.offers &&
              !p.offers.length &&
              newP &&
              newP.offers &&
              newP.offers.length)
          ) {
            p.offers = newP.offers;
          } else if (!p.offers) {
            p.offers = [];
          }
          p.serviceName = p.programName;
          if (newP) {
            p.id = newP.id;
          }
          return p;
        });
      }

      const a = [];
      for (const i in q.answers) {
        a.push(
          <>
            <span style={{ fontWeight: "bold" }}>{toCapitalizedWords(i)}:</span>{" "}
            {q.answers[i]}
            <br />
          </>,
        );
      }
      setHumanAnswers(a);
      setQuote(q);

      let d = q.convertedSale ? q.selectedPrograms : q.recommendedProgams;
      d = d.map((p) => {
        const icon = getServiceIcon(p.serviceType);

        const serviceUrl = `https://sales.lawnbot.biz/assets/servicebot-icons/${icon}${
          q.convertedSale ? "-selected" : ""
        }.png`;
        let apps = 0;
        let z = null;
        p.isPrepay = q.paymentTermSelected === "total";
        if (
          p.services &&
          (p.isPrepay ? p.services.prePay : p.services.application)
        ) {
          apps = p.services[p.isPrepay ? "prePay" : "application"].filter(
            (t) => !t.tooLateToDo,
          ).length;
        } else {
          z = formatV1Quote(p);
          apps = z.applications;
        }

        if (!p.programName) {
          p.programName = p.serviceName;
        }

        p.applications = apps;
        p.icon = serviceUrl;
        p.selected = true;
        p.serviceUrl = serviceUrl;

        /// account for realtime bug
        if (props.company.programs) {
          const fullProgram = props.company.programs.items.find(
            (program) => program.id === p.id,
          );
          p.realTime =
            props.company.crmSolution === "real-green" && fullProgram
              ? fullProgram.useRealGreenPricingEngine
              : false;
        }
        p.pCost = z
          ? z.pCost
          : priceCalculations.getDisplayPricing(
              p,
              q.paymentTermSelected,
              props.company.autoPayPriceMethod,
              props.company.autoPayMonthlyPricePeriod || 12,
              props.company.serviceTax,
              props.company.lookupZipCodeTax,
            );

        p.pCost = p.pCost.toFixed(2);
        const labels = installments.getInstallmentsLabels(
          props.company.autoPayPriceMethod,
          props.company.autoPayMonthlyPricePeriod || 12,
          p.applicationLabel,
          props.company.autoPayAlternativeLabel,
        );
        p.labels = labels;

        p.label =
          q.paymentTermSelected === "total"
            ? "total"
            : p.labels.switchLabel || "Application";

        p.pricingString = "";
        if (z) {
          p.pricingString = `${p.pCost} per ${toCapitalizedWords(
            p.applicationLabel || "Application",
          )}`;
        } else {
          if (p.realTime) {
            p.pricingString = offers.createRTPText(
              p.services[p.isPrepay ? "prePay" : "application"],
              currencySymbol,
              p.isPrepay,
            );
          } else if (p.offers.length) {
            p.pricingString = offers.createOfferText(
              p.services[p.isPrepay ? "prePay" : "application"][0].grossPrice,
              p.offers[0],
              p.applicationLabel,
              currencySymbol,
              p.isPrepay ? p.prePayDiscount : null,
            );
          } else {
            p.pricingString = p.firstAppPrice
              ? `${currencySymbol}${p.firstAppPrice.toFixed(
                  2,
                )} for the 1st application, $${p.applicationPrice.toFixed(
                  2,
                )} for remaining applications`
              : `${currencySymbol}${
                  p.services[
                    q.paymentTermSelected === "total" ? "prePay" : "application"
                  ][0].grossPrice
                } per ${toCapitalizedWords(
                  p.applicationLabel || "Application",
                )}`;
          }
        }

        const note = q.paymentTermSelected === "total" ? "" : labels.note;
        p.pricingString = `${p.pricingString} ${note}`;
        return p;
      });
      setDetails(d);
    };
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.quote]);

  useEffect(() => {
    if (typeof props.dontTax === "undefined" || null) return;
    setDontTax(props.dontTax);
  }, [props.dontTax]);

  let taxOverride = false;
  const currencySymbol = getCurrencySymbol(props.company.currency);

  const tabStyle = {
    paddingTop: 30,
    paddingLeft: 30,
    minHeight: 350,
    overflow: "scroll",
  };

  const getMonthlyPrice = (det) => {
    let monthlyPrice = 0;
    let found = false;
    // biome-ignore lint/complexity/noForEach: <explanation>
    det.forEach((i) => {
      const isUP = i.services.application[0].usesUpfrontPricing;
      if (isUP) {
        monthlyPrice += i.services.application[0].recurringPrice;
        found = true;
      }
    });

    return found ? (
      <div className="row">
        <div className="col-md-4">MONTHLY PAYMENT AMOUNT</div>
        <div className="col-md-4">
          {currencySymbol}
          {monthlyPrice.toFixed(2)}
        </div>
      </div>
    ) : null;
  };

  let isPrepay = false;
  let summary = {
    price: "N/A",
    tax: "N/A",
  };
  if (props.company && quote) {
    taxOverride =
      props.company.autoPayPriceMethod === "monthly-payment" &&
      quote.paymentTermSelected !== "total";
    if (
      details.length &&
      details[0].services &&
      details[0].services.application
    ) {
      try {
        summary = priceCalculations.getSummaryPricing(
          details,
          quote.paymentTermSelected,
          {
            autoPayPriceMethod: props.company.autoPayPriceMethod,
            autoPayMonthlyPricePeriod:
              props.company.autoPayMonthlyPricePeriod || 12,
            serviceTax: props.company.serviceTax,
            lookupZipCodeTax: props.company.lookupZipCodeTax,
            taxOverride: props.company.taxOverride,
            currency: props.company.currency,
          },
        );
      } catch (e) {
        console.log(e);
      }
    } else {
      /// in old version
      const first = details.map(() => {
        const arr = quote.convertedSale
          ? quote.selectedPrograms
          : quote.recommendedProgams;
        const ss = _.reduce(
          arr,
          (sum, ii) => {
            sum += Number.parseFloat(ii.pCost);
            return sum;
          },
          0.0,
        );

        return ss;
      });
      summary = { price: _.reduce(first, (sum, jj) => (sum += jj)), tax: 0.0 };
    }
  }
  // a check needed for some quotes that hit a bug with the taxes
  if (typeof summary.tax !== "string" && isNaN(summary.tax)) {
    summary.tax = 0;
  }
  return (
    <>
      <ul className="nav nav-tabs">
        {quote && !quote.convertedSale ? (
          <li className="active">
            <a href="#sale_details" data-toggle="tab">
              Quote Details
            </a>
          </li>
        ) : (
          <li className="active" data-toggle="tab">
            <a href="#sale_details" data-toggle="tab">
              Sale Details
            </a>
          </li>
        )}
        <li>
          <a href="#service_questions" data-toggle="tab">
            User Feedback
          </a>
        </li>
        <li>
          <a href="#chat_history" data-toggle="tab">
            Chat History
          </a>
        </li>
      </ul>
      <div className="tab-content">
        <div
          className="tab-pane active tab-content"
          id="sale_details"
          style={tabStyle}
        >
          {/* never made it to quote */}
          {quote && !quote.convertedSale && !quote.recommendedProgams.length ? (
            <div>
              <strong>No Quote Provided</strong>
              <br />
              <br />
              <strong>Service Types Selected</strong>
              <br />
              {!quote.serviceTypes.length
                ? "None"
                : quote.serviceTypes.map((t, i) => (
                    <div key={i}>
                      *{t}
                      <br />
                    </div>
                  ))}
            </div>
          ) : null}

          {quote && details && details.length
            ? details.map((p, i) => {
                // let method = () => {};

                const isUpfront = p.services.application[0].usesUpfrontPricing;

                const monthyPrice = isUpfront
                  ? p.services.application[0].recurringPrice.toFixed(2)
                  : null;

                let pricingString;
                let serviceUrl;
                let pCost = 0;
                let note;
                let programName;
                let applications;

                if (p.services?.application) {
                  pricingString = p.pricingString;
                  serviceUrl = p.serviceUrl;
                  pCost = p.pCost;
                  note = p.note;
                  isPrepay = p.isPrepay;
                  programName = p.programName;
                  applications = p.applications;
                } else {
                  const z = formatV1Quote(p);
                  pricingString = z.pricingString;
                  serviceUrl = z.serviceUrl;
                  pCost = z.pCost;
                  note = z.note;
                  isPrepay = z.isPrepay;
                  programName = z.programName;
                  applications = z.applications;
                }

                if (pCost && isNaN(pCost)) {
                  pCost = Number.parseFloat(pCost).toFixed(2);
                } else {
                  pCost = Number.parseFloat(pCost).toFixed(2);
                }

                // const {} = transformer()
                return (
                  <div key={i} className="row" style={{ marginBottom: 10 }}>
                    <div className="col-md-1">
                      <img
                        src={serviceUrl}
                        style={{ width: 70, borderRadius: 10 }}
                        alt=""
                      />
                    </div>
                    <div className="col-md-11" style={{ paddingTop: 10 }}>
                      <strong style={{ fontSize: 15 }}>
                        {programName} - {currencySymbol}
                        {pCost}
                      </strong>
                      <br />
                      {isUpfront ? (
                        <div>
                          Upfront Fee (<b>monthly</b> fee of {currencySymbol}
                          {monthyPrice}){" "}
                        </div>
                      ) : (
                        <div>
                          {applications} applications {pricingString}
                        </div>
                      )}

                      <br />
                      {note ? (
                        <span style={{ fontStyle: "italic" }}>{note}</span>
                      ) : null}
                      <br />
                    </div>
                  </div>
                );
              })
            : null}
          <hr />
          <br />
          {quote &&
          details.length &&
          summary &&
          typeof summary.price !== "undefined" ? (
            <div style={{ fontSize: 17, fontFamily: `'Inter', sans-serif;` }}>
              <div className="row">
                <div className="col-md-4">
                  SUBTOTAL {isPrepay ? ` with Pre-Pay Discount` : ""}
                </div>
                <div className="col-md-4">
                  {currencySymbol}
                  {summary.price.toFixed(2)}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">SERVICE TAX</div>
                <div className="col-md-4">
                  {taxOverride || dontTax ? (
                    ""
                  ) : (
                    <span>
                      {currencySymbol}
                      {Number.parseFloat(`${summary.tax}`).toFixed(2)}
                    </span>
                  )}
                </div>
              </div>
              <div className="row" style={{ fontWeight: "bold" }}>
                <div className="col-md-4">GET STARTED TODAY</div>
                <div className="col-md-4">
                  {currencySymbol}
                  {`${Number.parseFloat(
                    returnTaxString(summary, taxOverride, dontTax),
                  ).toFixed(2)}`}
                </div>
              </div>
              {getMonthlyPrice(details)}

              <br />
              {/* {!quote.convertedSale && quote.paymentTermSelected !== 'application' ? (<strong>Pre-Pay and Save...........................  ${prePayDiscount.toFixed(2)}</strong>) : null} */}
            </div>
          ) : null}
        </div>
        {/* end tab 2 */}
        <div
          className="tab-pane tab-content"
          id="service_questions"
          style={tabStyle}
        >
          <span style={{ fontWeight: "bold" }}>User Feedback:</span>
          <br />
          <br />
          {humanAnswers.length ? (
            humanAnswers.map((i) => {
              return i;
            })
          ) : (
            <span>No Feedback Provided</span>
          )}
        </div>
        {/* end tab 3 */}
        <div
          className="tab-pane tab-content"
          id="chat_history"
          style={tabStyle}
        >
          <p>Coming Soon...</p>
        </div>
      </div>
    </>
  );
};

QuoteTabsWidget.propTypes = {
  quote: PropTypes.object.isRequired,
};

const toCapitalizedWords = (name) => {
  var words = name.match(/[A-Za-z][a-z]*/g) || [];

  return words.map(capitalize).join(" ");
};

const capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.substring(1);
};

const returnTaxString = (summary, taxOverride, dontTax) => {
  let returnString = "";
  if (dontTax) {
    // do nothing
    returnString = `${summary.price}`;
  } else if (isNaN(summary.tax) && !taxOverride) {
    returnString = `${summary.price} ${summary.tax}`;
  } else {
    returnString = `${summary.price + summary.tax}`;
  }
  return returnString;
};
