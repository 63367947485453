import React, { useEffect, useState, Fragment } from "react";

import { API } from "aws-amplify";

import { Spinner } from "react-bootstrap";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../components/CompanySelector.jsx";
import ResetPassword from "../scenes/settings/ResetPassword.jsx";
import useAuthUser from "../hooks/auth/useAuthUser.js";
import CreateUser from "../components/auth/CreateUser.jsx";

export const SettingsScreen = (props) => {
  const { user, loading: isAuthUserLoading } = useAuthUser();

  const [client, setClient] = useState(null);
  const [users, setUsers] = useState([]);

  const activeCompanySnapshot = useSnapshot(activeCompanyState);

  const nonCurrentUsers = users.filter((i) => i.email !== user.email);

  useEffect(() => {
    const fn = async () => {
      const client = await API.get(
        "dashboardapi",
        "/v1/management/settings",
        {},
      );
      setUsers(client.users.items);
      setClient(client);
    };

    fn();
  }, []);

  if (isAuthUserLoading) {
    return (
      <div id="crm-spinner">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <>
      <div className="page-title">
        <div className="title">Settings</div>
        <div className="sub-title">{client ? client.name : "  "}</div>
      </div>
      {/* client info */}
      {client ? (
        <>
          <div className="row">
            <div className="col-md-12">
              <div className="card bg-white card-block">
                <h5>Company Info</h5>
                <strong>{client.name}</strong>
              </div>
            </div>
          </div>

          {/* end client info */}
          {/* branches */}
          <div className="row">
            <div className="col-md-12">
              <div className="card bg-white card-block">
                <h5>Connected Branches</h5>
                <ul>
                  {client.branches.items.map((i) => {
                    const key = `branch_id_${Math.floor(Math.random() * 10000)}`;
                    return (
                      <li key={key}>
                        <strong>{i.name}</strong> (Branch ID: ...
                        {i.builderCompanyId.substring(
                          i.builderCompanyId.length - 10,
                          10,
                        )}{" "}
                        ){" "}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          {/*  end branches */}

          {/* users */}
          {props.isAdmin && (
            <CreateUser
              users={users}
              setUsers={setUsers}
              companyId={activeCompanySnapshot?.companyId}
            />
          )}
          {props.isAdmin && nonCurrentUsers.length && client?.id ? (
            <ResetPassword
              // ignores current user in list
              users={nonCurrentUsers}
              clientId={client.id}
            />
          ) : null}
        </>
      ) : null}
      {/* end users */}
    </>
  );
};
