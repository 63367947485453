import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Spinner, Col, Row, Container } from "react-bootstrap";
import { API } from "aws-amplify";
import { ToastContainer, toast } from "react-toastify";
import S3ImageUploader from "./image-uploader/S3ImageUploader";
import "react-toastify/dist/ReactToastify.css";
import "./brandingInfo.css";
import { StyleContext } from "../../styling/styleContext";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../../components/CompanySelector.jsx";
import useUpdateBranding from "../../hooks/setup/useUpdateBrandingImages.js";

function BrandingInfo({ openVersionControl }) {
  const style = useContext(StyleContext);
  const [branding, setBranding] = useState({});
  const [loading, setLoading] = useState(true);
  const [textAreaHeight, setTextAreaHeight] = useState(38);
  const activeCompanySnapshot = useSnapshot(activeCompanyState);
  const { loading: updatingBrandingImages, updateBrandingImages } =
    useUpdateBranding(activeCompanySnapshot, branding, setBranding);
  useEffect(() => {
    const fn = async () => {
      setLoading(true);
      const company = await API.get("dashboardapi", "/v1/company", {
        headers: {
          "Company-Id": activeCompanySnapshot?.companyId,
        },
      });
      setBranding(company.branding);
      setLoading(false);
    };

    fn();
  }, [activeCompanySnapshot?.companyId]);

  const saveTagLine = async (type) => {
    if (branding.id) {
      await API.post("dashboardapi", "/v1/companyBranding", {
        headers: {
          "Company-Id": activeCompanySnapshot?.companyId,
        },
        body: { data: { payload: branding } },
      });
      toast(`${type} saved`);
    }
  };

  const headerStyling = {
    ...style.styleSizing,
    fontWeight: 500,
    color: style.colors.blue,
    marginBottom: 20,
    fontSize: 20,
    paddingLeft: 5,
  };

  return loading || updatingBrandingImages ? (
    <div id="crm-spinner">
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  ) : (
    <div
      style={{
        maxWidth: "1440px",
        background: "whitesmoke",
        borderRadius: "25px",
        padding: "25px",
        marginTop: "20px",
      }}
    >
      <ToastContainer />
      {/* <div style={{ display: "flex" }}> */}
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <h3
            id="real-green-header"
            style={{ ...style.styleSizing, marginBottom: 0, fontSize: 20 }}
          >
            Branding Info
          </h3>
          <div>
            <Button
              disabled={!branding || !branding.id}
              style={{
                ...style.buttonStyle(style.colors.green),
                background: style.colors.green,
                border: `1px solid ${style.colors.white}`,
                marginBottom: 20,
              }}
              onClick={() => {
                openVersionControl("companyBranding", branding.id);
              }}
            >
              Version Control
            </Button>
          </div>
        </div>

        <div className="container">
          <Form.Group className="form_group">
            <Form.Label
              htmlFor="tagline"
              style={{ ...style.styleSizing, color: style.colors.green }}
            >
              {" "}
              <i style={{ marginRight: 5 }} className="fa fa-floppy-o" />
              Tag Line
            </Form.Label>
            <Form.Control
              style={{
                ...style.styleSizing,
                color: style.colors.blue,
                fontSize: 12,
              }}
              type="text"
              id="tagline"
              value={branding.tagLine || ""}
              onChange={(e) => {
                setBranding({ ...branding, tagLine: e.target.value });
              }}
              onBlur={(e) => {
                setBranding({ ...branding, tagLine: e.target.value });
              }}
              placeholder="The Best Lawncare In The Area"
            />
          </Form.Group>
          <Button
            className="branding-button"
            color="primary"
            variant="contained"
            style={{
              ...style.styleSizing,
              paddingBottom: 5,
              color: "#fff",
              backgroundColor: style.colors.green,
              marginLeft: 35,
              borderRadius: 5,
            }}
            onClick={() => saveTagLine("Tag Line")}
          >
            Save TagLine
          </Button>
        </div>

        <div className="container value_description_container">
          <Form.Group className="form_group">
            <Form.Label
              htmlFor="valueaddescription"
              id="value-add-description"
              className="context"
              style={{
                ...style.styleSizing,
                color: style.colors.green,
                paddingLeft: 5,
              }}
            >
              Value Add Description
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={1}
              value={branding.valueAddDescription}
              style={{
                ...style.styleSizing,
                height: textAreaHeight,
                color: style.colors.blue,
                fontSize: 12,
              }}
              onChange={(e) => {
                if (e.target.scrollHeight !== textAreaHeight) {
                  setTextAreaHeight(e.target.scrollHeight);
                }
                setBranding({
                  ...branding,
                  valueAddDescription: e.target.value,
                });
              }}
              onBlur={(e) => {
                setBranding({
                  ...branding,
                  valueAddDescription: e.target.value,
                });
              }}
              id="value-add-description"
              placeholder="We have been in business 20 years..."
            />
          </Form.Group>
          <Button
            className="branding-button"
            style={{
              ...style.styleSizing,
              paddingBottom: 5,
              color: "#fff",
              backgroundColor: style.colors.green,
              marginLeft: 60,
              borderRadius: 5,
            }}
            color="primary"
            variant="contained"
            onClick={() => saveTagLine("Value Add Description")}
          >
            <span>Save Value Add Description</span>
          </Button>
        </div>
      </Container>
      {/* </div> */}

      <Container>
        <Row>
          <Col xs="12" lg="6" md="6">
            <h2
              className="context real-green-header"
              id="company-logo"
              style={{
                ...headerStyling,
              }}
            >
              Company Logo
            </h2>
            <S3ImageUploader
              aspect={4 / 1}
              startLoading={() => {}}
              image={branding.companyLogo}
              filename={"logo"}
              type={"companyLogo"}
              uploaded={updateBrandingImages}
              allowableContentType="image/*"
            />
          </Col>
          <Col xs="12" lg="6" md="6">
            <h2
              className="context real-green-header"
              id="company-avatar"
              style={{ ...headerStyling }}
            >
              Company Avatar
            </h2>
            <S3ImageUploader
              aspect={1 / 1}
              startLoading={() => {}}
              image={branding.companyAvatar}
              filename={"avatar"}
              type={"companyAvatar"}
              circular={true}
              uploaded={updateBrandingImages}
              allowableContentType="image/*"
            />
          </Col>
        </Row>

        <Row>
          <Col xs="12" lg="6" md="6">
            <h2
              className="context real-green-header"
              id="value-add-image"
              style={{ ...headerStyling }}
            >
              Value Add Image ( Team Image )
            </h2>
            <S3ImageUploader
              aspect={4 / 1}
              startLoading={() => {}}
              image={branding.valueAddImage}
              filename={"valueadd"}
              type={"valueAddImage"}
              uploaded={updateBrandingImages}
              allowableContentType="image/*"
            />
          </Col>
          <Col xs="12" lg="6" md="6">
            <h2
              style={{ ...headerStyling }}
              className="context real-green-header"
              id="quote-modal-image"
            >
              Quote Modal Image
            </h2>
            <S3ImageUploader
              aspect={4 / 1}
              startLoading={() => {}}
              image={branding.quoteModalImage}
              filename={"quotemodal"}
              type={"quoteModalImage"}
              uploaded={updateBrandingImages}
              allowableContentType="image/*"
            />
          </Col>
          <Col xs="12" lg="6" md="6">
            <h2
              className="context real-green-header"
              id="learn-more-image"
              style={{ ...headerStyling }}
            >
              {" "}
              Learn More Image or Video
            </h2>
            <S3ImageUploader
              aspect={4 / 1}
              startLoading={() => {}}
              image={branding.aboutUsAssetUrl}
              filename={"aboutUsasset"}
              type={"aboutUsAssetUrl"}
              uploaded={updateBrandingImages}
              allowableContentType="video/mp4,image/*"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default BrandingInfo;
