import React, { useState, useEffect, useContext, memo, Fragment } from "react";
import { Button, Spinner, Dropdown, Alert } from "react-bootstrap";
import { API } from "aws-amplify";
import { deleteKeys } from "./versionUtils";
import moment from "moment";
import { MessageStepDisplay } from "../sortable-table/Draggables/MessageStepDisplay.jsx";
import VersionConfirmationModal from "./VersionConfirmationModal.jsx";
import "./version-control.css";
import StyleContext from "../../styling/styleContext";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../../components/CompanySelector.jsx";

function unCamalize(string) {
  return (
    string.charAt(0).toUpperCase() +
    string
      .slice(1)
      .split(/(?=[A-Z])/)
      .join(" ")
  );
}
function VersionControl({
  open,
  toggle,
  type,
  id,
  botQuestionType,
  customFields,
}) {
  const activeCompanySnapshot = useSnapshot(activeCompanyState);
  const style = useContext(StyleContext);
  const [versions, setVersions] = useState([]);
  const [v0, setVersion0] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  useEffect(() => {
    const fn = async () => {
      setLoading(true);
      const allVersions = await API.get(
        "dashboardapi",
        `/v1/versioning/${type}/${id}`,
        {
          headers: {
            "Company-Id": activeCompanySnapshot?.companyId,
          },
        },
      );
      const v0 = { ...allVersions[0] };
      allVersions.shift();
      const sortedVersions = allVersions.sort((a, b) => {
        const aOrder = Number.parseInt(a.version.substring(1));
        const bOrder = Number.parseInt(b.version.substring(1));
        return aOrder > bOrder ? 1 : bOrder > aOrder ? -1 : 0;
      });
      setVersions(sortedVersions);
      setSelectedVersion(sortedVersions[0]);
      setVersion0(v0);
      setLoading(false);
    };
    if (open && type && id) {
      fn();
    } else if (!open) {
      setVersions([]);
      setSelectedVersion(null);
      setLoading(true);
    }
  }, [open, type, id, activeCompanySnapshot?.companyId]);

  let displayData = [];
  const versionList = [];
  versions.forEach((version, i) => {
    versionList.push(
      <Dropdown.Item
        active={
          selectedVersion && selectedVersion.version === version.version
            ? true
            : false
        }
        onSelect={() => setSelectedVersion(version)}
        key={i}
      >{`${version.version} - ${moment(version.createdAt).format(
        "YYYY-MM-DD",
      )}`}</Dropdown.Item>,
    );
  });

  if (selectedVersion && v0 && selectedVersion.id && type) {
    if (botQuestionType) {
      displayData = createBotQuestionsDisplay(
        selectedVersion,
        v0,
        botQuestionType,
      );
    } else {
      displayData = setDisplayData(selectedVersion, type, v0, customFields);
    }
  }
  return (
    <div style={{ marginTop: 20 }}>
      <Alert variant={"warning"}>
        <h3>{`RollBack Version - ${unCamalize(type)}`}</h3>
        <p>
          {`WARNING!!! This is a rollback feature for all data that is versioned,
          meaning if information regarding your company/programs/services etc.
          is accidently updated/lost, you may be able to go back to a what was
          there before. Use the dropdown to examine each version and find what
          you may need before clicking 'confirm'. Click 'cancel to return to the
          previous screen`}{" "}
        </p>
        {!loading ? (
          <div>
            <Dropdown>
              <Dropdown.Toggle
                style={{
                  ...style.submitButton(style.colors.green),
                  width: "100%",
                }}
              >
                {`${selectedVersion.version} - ${moment(
                  selectedVersion.createdAt,
                ).format("YYYY-MM-DD")}`}
              </Dropdown.Toggle>
              <Dropdown.Menu>{versionList}</Dropdown.Menu>
            </Dropdown>
            <div style={{ marginTop: 20, textAlign: "right" }}>
              <Button
                className="btn-submit"
                color="primary"
                style={{
                  ...style.submitButton(style.colors.green),
                  marginRight: 20,
                }}
                onClick={() => setConfirmationOpen(true)}
              >
                Confirm
              </Button>
              <Button
                color="secondary"
                style={{ ...style.submitButton(style.colors.green) }}
                onClick={() => toggle()}
              >
                Cancel
              </Button>
              <VersionConfirmationModal
                open={confirmationOpen}
                hideModal={() => setConfirmationOpen(false)}
                version={selectedVersion ? selectedVersion.version : ""}
                rollBack={async () => {
                  await (type === "bot"
                    ? rollBackBot(selectedVersion, type, botQuestionType, v0)
                    : rollBack(selectedVersion, type, v0, customFields));
                  setConfirmationOpen(false);
                  toggle();
                }}
              />
            </div>
          </div>
        ) : (
          <p>CURRENTLY LOADING VERSIONS</p>
        )}
      </Alert>

      {loading ? (
        <div id="crm-spinner">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div>{displayData}</div>
      )}
    </div>
  );
}

function setDisplayData(selectedVersion, type, v0, customFields) {
  const keysToDisplay = customFields
    ? customFields
    : deleteKeys(selectedVersion, type);
  const result = [];
  Object.keys(keysToDisplay).forEach((key) => {
    if (key !== "id") {
      const keyName = unCamalize(key);
      result.push(
        <div
          style={{
            ...(v0[key] !== selectedVersion[key]
              ? { backgroundColor: "#fdf4ea" }
              : {}),
          }}
          className={"version-row"}
        >
          <div className={"version-column"}>{`${keyName}: `}</div>
          <div className={"version-column"}>
            {v0[key] &&
            typeof v0[key] === "string" &&
            v0[key].includes(
              "https://lawnbotcompanystorage161827-dev.s3.amazonaws.com",
            ) ? (
              <img
                style={{ width: "50%" }}
                src={v0[key].split("?")[0]}
                alt="branding"
              />
            ) : (
              <p>{`${v0[key]}`}</p>
            )}
          </div>
          <div className={"version-column"}>
            {selectedVersion[key] &&
            typeof selectedVersion[key] === "string" &&
            selectedVersion[key].includes(
              "https://lawnbotcompanystorage161827-dev.s3.amazonaws.com",
            ) ? (
              <img
                style={{ width: "50%" }}
                src={selectedVersion[key].split("?")[0]}
                alt="branding"
              />
            ) : (
              <p>{`${selectedVersion[key]}`}</p>
            )}
          </div>
        </div>,
      );
    }
  });
  return (
    <Fragment>
      <div style={{ borderBottom: "1px solid" }} className={"version-row"}>
        <div style={{}} className={"version-headers version-column"}>
          Field
        </div>
        <div style={{}} className={"version-headers version-column"}>
          Current Version (v0)
        </div>
        <div style={{}} className={"version-headers version-column"}>
          Selected Version
        </div>
      </div>
      {result}
    </Fragment>
  );
}

function createBotQuestionsDisplay(selectedVersion, v0, botQuestionType) {
  const v0BotQuestions = JSON.parse(v0[botQuestionType])[0];
  const botQuestions = JSON.parse(selectedVersion[botQuestionType])[0];
  const v0QuestionsArr = v0BotQuestions.steps.concat(
    v0BotQuestions.probing_questions,
  );
  const fullQuestionsArr = botQuestions.steps.concat(
    botQuestions.probing_questions,
  );
  const length =
    v0QuestionsArr.length > fullQuestionsArr.length
      ? v0QuestionsArr.length
      : fullQuestionsArr.length;
  const result = [];
  for (let i = 0; i < length; i++) {
    result.push(
      <div
        className="version-row"
        style={{
          ...(JSON.stringify(v0[i]) !== JSON.stringify(selectedVersion[i])
            ? { backgroundColor: "#fdf4ea" }
            : {}),
        }}
      >
        <div index={i} className={"version-column"}>
          {v0QuestionsArr[i] && (
            <MessageStepDisplay
              openModal={() => {}}
              containerStyle={{ margin: "auto", width: "80%" }}
              message={v0QuestionsArr[i]}
              provided={{}}
            />
          )}
        </div>
        <div index={i} className={"version-column"}>
          {fullQuestionsArr[i] && (
            <MessageStepDisplay
              openModal={() => {}}
              containerStyle={{ margin: "auto", width: "80%" }}
              message={fullQuestionsArr[i]}
              provided={{}}
            />
          )}
        </div>
      </div>,
    );
  }
  return (
    <Fragment>
      <div style={{ borderBottom: "1px solid" }} className={"version-row"}>
        <div className={"version-headers version-column"}>
          Current Version (v0)
        </div>
        <div className={"version-headers version-column"}>Selected Version</div>
      </div>
      {result}
    </Fragment>
  );
}

async function rollBack(selectedVersion, type, v0, customFields) {
  let payload = {};
  if (customFields) {
    payload = { ...v0 };
    Object.keys(customFields).forEach(
      (key) => (payload[key] = selectedVersion[key]),
    );
  } else {
    payload = { ...selectedVersion };
  }
  try {
    const updatedVersion = await API.put(
      "dashboardapi",
      `/v1/versioning/${type}/${selectedVersion.id}`,
      {
        body: selectedVersion,
      },
    );
    return updatedVersion;
  } catch (err) {
    console.log(err);
  }
}

async function rollBackBot(selectedVersion, type, botQuestionType, v0) {
  try {
    if (!botQuestionType) {
      delete selectedVersion["objections"];
      delete selectedVersion["preModal"];
      delete selectedVersion["serviceQuestions"];
    }
    const updatedVersion = await API.put(
      "dashboardapi",
      `/v1/versioning/${type}/${selectedVersion.id}`,
      {
        body: { ...v0, ...selectedVersion },
      },
    );
    return updatedVersion;
  } catch (err) {
    console.log(err);
  }
}

export default memo(VersionControl);
