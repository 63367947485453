import React, { useState, useContext } from "react";
import { Button, FormControl, Card, Form } from "react-bootstrap";
import StyleContext from "../../../../styling/styleContext";
const RatingForm = ({ botStep, setBotStep }) => {
  const style = useContext(StyleContext);
  const handleChange = (input) => {
    setBotStep({
      ...botStep,
      component: {
        type: "rating",
        placeholder: input,
      },
    });
  };

  return (
    <>
      <h6 style={{ ...style.styleSizing, color: style.colors.blue }}>Rating</h6>
      {/* {formType === "rating"
        ? inputData.map((inp, index) => {
            return (
              <li key={`user_input_${index}`}>
                <Card className="option-card">
                  <div>
                    {inp}
                    <Button onClick={() => removeInput(index)}>
                      <span aria-hidden="true">&times;</span>
                    </Button>
                  </div>
                </Card>
              </li>
            );
          })
        : null}
      <> */}
      <FormControl
        className="modal-input"
        onChange={(e) => handleChange(e.target.value)}
        type="text"
        placeholder="placeholder"
      />
    </>
  );
};

export default RatingForm;
